<template>
    <vx-card
        title="Assets & Liabilities"
        collapse-action
        startCollapsed
        :edit-action="!locked"
        :subtitle="subTitle"
        @edit="editAssets"
    >
        <vs-list>
            <vs-list-item
                v-if="assets.residential_property"
                title="Residential Property"
                :subtitle="
                    assets.residential_property_owner
                        ? this.capitalise(assets.residential_property_owner)
                        : assets.residential_property_joint
                        ? 'Joint'
                        : 'Sole'
                "
                >{{
                    currency(assets.residential_property_amount)
                }}</vs-list-item
            >
            <vs-list-item
                v-if="assets.new_residential_property"
                title="New Residential Property"
                :subtitle="
                    assets.new_residential_property_owner
                        ? this.capitalise(assets.new_residential_property_owner)
                        : assets.new_residential_property_joint
                        ? 'Joint'
                        : 'Sole'
                "
                >{{
                    currency(assets.new_residential_property_amount)
                }}</vs-list-item
            >
            <vs-list-item
                v-if="assets.blt_property"
                title="Buy To Let Property"
                :subtitle="assets.blt_property_joint ? 'Joint' : 'Sole'"
                >{{ currency(assets.blt_property_amount) }}</vs-list-item
            >
            <vs-list-item
                v-if="assets.savings_bank"
                title="Savings/Bank"
                :subtitle="assets.savings_bank_joint ? 'Joint' : 'Sole'"
                >{{ currency(assets.savings_bank_amount) }}</vs-list-item
            >
            <vs-list-item
                v-if="assets.life_insurance"
                title="Life Insurance"
                :subtitle="assets.life_insurance_joint ? 'Joint' : 'Sole'"
                >{{ currency(assets.life_insurance_amount) }}</vs-list-item
            >
            <vs-list-item v-if="assets.vehicle" title="Vehicle">{{
                currency(assets.vehicle_amount)
            }}</vs-list-item>
            <vs-list-item v-if="assets.pension" title="Pension">{{
                currency(assets.pension_amount)
            }}</vs-list-item>
            <vs-list-item
                v-if="assets.shares_investments"
                title="Shares/Investments"
                :subtitle="assets.shares_investments_joint ? 'Joint' : 'Sole'"
                >{{ currency(assets.shares_investments_amount) }}</vs-list-item
            >
            <vs-list-item
                v-if="assets.endowment"
                title="Endowment"
                :subtitle="assets.endowment_joint ? 'Joint' : 'Sole'"
                >{{ currency(assets.endowment_amount) }}</vs-list-item
            >
            <vs-list-item
                v-if="assets.business"
                title="Business"
                :subtitle="assets.business_type + ' ' + assets.shareholders"
                >{{ currency(assets.business_amount) }}</vs-list-item
            >
            <vs-list-item v-if="assets.household" title="Household">{{
                currency(assets.household_amount)
            }}</vs-list-item>
            <vs-list-item v-if="assets.gold_jewellery" title="Gold/Jewellery">{{
                currency(assets.gold_jewellery_amount)
            }}</vs-list-item>
            <vs-list-item
                v-if="assets.residential_mortgage"
                title="Residential Mortgage"
                :subtitle="assets.residential_mortgage_joint ? 'Joint' : 'Sole'"
                >{{
                    currency(assets.residential_mortgage_amount)
                }}</vs-list-item
            >
            <vs-list-item
                v-if="assets.new_residential_mortgage"
                title="New Residential Mortgage"
                :subtitle="
                    assets.new_residential_mortgage_joint ? 'Joint' : 'Sole'
                "
                >{{
                    currency(assets.new_residential_mortgage_amount)
                }}</vs-list-item
            >
            <vs-list-item
                v-if="assets.blt_mortgage"
                title="Buy to Let Mortgage"
                :subtitle="assets.blt_mortgage_joint ? 'Joint' : 'Sole'"
                >{{ currency(assets.blt_mortgage_amount) }}</vs-list-item
            >
            <vs-list-item v-if="assets.credit_cards" title="Credit Cards">{{
                currency(assets.credit_cards_amount)
            }}</vs-list-item>
            <vs-list-item v-if="assets.loans" title="Loans">{{
                currency(assets.loans_amount)
            }}</vs-list-item>
            <vs-list-item
                v-if="assets.assets_outside_uk"
                title="Assets outside of UK"
                subtitle="Has assets outside of the UK"
            ></vs-list-item>
            <vs-list-item
                v-if="assets.known_by_other_name === 'yes'"
                title="Known by other names"
                :subtitle="assets.other_name"
            ></vs-list-item>
        </vs-list>
        <AssetsModal
            :assets="assets"
            :show="show.assetsModal"
            @close="closeAssetsModal"
            @changed="$emit('changed')"
            @newData="$emit('newDataAssets', $event)"
        ></AssetsModal>
    </vx-card>
</template>

<script>
import AssetsModal from '../Modals/AssetsModal'
import { textFormat } from '@/mixins/TextFormat'

export default {
    name: 'AssetsCard',
    mixins: [textFormat],
    props: ['assets', 'locked'],
    components: {
        AssetsModal,
    },
    data() {
        return {
            show: {
                assetsModal: false,
            },
        }
    },
    computed: {
        subTitle() {
            var total = 0
            var liabilities = 0
            if (
                this.assets.residential_property &&
                !this.assets.new_residential_property
            ) {
                if (this.assets.residential_property_joint)
                    total += this.assets.residential_property_amount / 2
                else total += this.assets.residential_property_amount
            }
            if (this.assets.new_residential_property) {
                if (this.assets.new_residential_property_joint)
                    total += this.assets.new_residential_property_amount / 2
                else total += this.assets.new_residential_property_amount
            }
            if (this.assets.blt_property) {
                if (this.assets.blt_property_joint) {
                    total += this.assets.blt_property_amount / 2
                } else total += this.assets.blt_property_amount
            }
            if (this.assets.savings_bank) {
                if (this.assets.saving_bank_joint)
                    total += this.assets.savings_bank_amount / 2
                else total += this.assets.savings_bank_amount
            }
            if (this.assets.life_insurance) {
                if (this.assets.life_insurance_joint)
                    total += this.assets.life_insurance_amount / 2
                else total += this.assets.life_insurance_amount
            }
            if (this.assets.vehicle) total += this.assets.vehicle_amount
            if (this.assets.pension) total += this.assets.pension_amount
            if (this.assets.shares_investments) {
                if (this.assets.shares_investments_joint)
                    total += this.assets.shares_investments_amount / 2
                else total += this.assets.shares_investments_amount
            }
            if (this.assets.endowment) {
                if (this.assets.endowment_joint)
                    total += this.assets.endowment_amount / 2
                else total += this.assets.endowment_amount
            }
            if (this.assets.business) total += this.assets.business_amount
            if (this.assets.household) total += this.assets.household_amount
            if (this.assets.gold_jewellery)
                total += this.assets.gold_jewellery_amount

            if (this.assets.residential_mortgage) {
                if (this.assets.residential_mortgage_joint)
                    liabilities += this.assets.residential_mortgage_amount / 2
                else liabilities += this.assets.residential_mortgage_amount
            }
            if (this.assets.new_residential_mortgage) {
                if (this.assets.new_residential_mortgage_joint)
                    liabilities +=
                        this.assets.new_residential_mortgage_amount / 2
                else liabilities += this.assets.new_residential_mortgage_amount
            }
            if (this.assets.blt_mortgage) {
                if (this.assets.blt_mortgage_joint)
                    liabilities += this.assets.blt_mortgage_amount / 2
                else liabilities += this.assets.blt_mortgage_amount
            }
            if (this.assets.credit_cards)
                liabilities += this.assets.credit_cards_amount
            if (this.assets.loans) liabilities += this.assets.loans_amount

            return 'Net worth ' + this.currency(total - liabilities)
        },
    },
    methods: {
        label(value) {
            var colour = ''
            var label = ''

            if (value) colour = 'success'
            else if (value === null) colour = 'rgb(195, 195, 195)'
            else if (!value) colour = 'danger'

            if (value) label = 'Requested'
            else if (value === null) label = 'Not offered'
            else if (!value) label = 'Declined'
            return [colour, label]
        },
        closeAssetsModal() {
            this.show.assetsModal = false
        },
        editAssets() {
            this.show.assetsModal = true
        },
    },
}
</script>
