<template>
    <SideBarModal title="Other Gift" :show="show" @close="$emit('close')">
        <template v-slot:content>
            <div v-if="giftData">
                <h5>Gift to</h5>
                <div class="vx-row mb-2">
                    <div class="vx-col w-full">
                        <PersonSelect
                            :personData="
                                giftData &&
                                giftData.beneficiary &&
                                giftData.beneficiary.id
                                    ? giftData.beneficiary.id
                                    : giftData.beneficiary
                            "
                            :peopleData="peopleData"
                            label="Beneficiary"
                            :horizontal="true"
                            @selectedPerson="setPerson"
                        />
                        <vs-divider>or</vs-divider>
                    </div>
                    <div class="vx-col w-full mb-3">
                        <vs-input
                            type="text"
                            v-model="giftData.beneficiary_group"
                            label="Group"
                            placeholder="Beneficiary Group"
                            class="w-full"
                        />
                    </div>
                    <div class="vx-col w-full">
                        <GroupShareOptions
                            :shareData="giftData.beneficiary_group_share"
                            @selectedShare="setShare"
                            :horizontal="true"
                        ></GroupShareOptions>
                    </div>
                    <vs-divider />
                </div>
                <h5 class="mb-3">Description</h5>
                <div class="vx-row mb-2">
                    <div class="vx-col w-full">
                        <vs-textarea
                            v-model="giftData.item"
                            label="Gift description"
                        />
                    </div>
                    <div class="vx-col flex w-full mb-3 mt-3">
                        <label>Before Partner</label>
                        <vs-switch
                            v-model="giftData.gift_before_partner"
                            class="ml-auto"
                        />
                    </div>
                    <div class="vx-col flex w-full">
                        <label>After Partner</label>
                        <vs-switch
                            v-model="giftData.gift_after_partner"
                            class="ml-auto"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <vs-button class="mr-6" @click="giftSave" :disabled="!validateForm"
                >Save</vs-button
            >
            <vs-button type="border" color="danger" @click="$emit('close')"
                >Cancel</vs-button
            >
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'
import PersonSelect from '@/components/common/PersonSelect'
import GroupShareOptions from '@/components/common/GroupShareOptions'
import SideBarModal from '@/components/common/SideBarModal'

export default {
    name: 'OtherGiftModal',
    components: {
        SideBarModal,
        PersonSelect,
        GroupShareOptions,
    },
    props: ['gift', 'show', 'peopleData', 'userId'],
    watch: {
        gift: {
            deep: true,
            immediate: true,
            handler(data) {
                if (data) this.giftData = { ...data }
                else this.clearForm()
            },
        },
    },
    data() {
        return {
            giftData: {
                id: null,
                user: null,
                beneficiary: null,
                beneficiary_group: null,
                beneficiary_group_share: null,
                item: null,
                gift_before_partner: false,
                gift_after_partner: false,
            },
        }
    },
    computed: {
        validateForm() {
            if (
                (this.giftData.beneficiary ||
                    (this.giftData.beneficiary_group_share &&
                        this.giftData.beneficiary_group)) &&
                this.giftData.item
            )
                return true
            return false
        },
    },
    methods: {
        giftSave() {
            if (this.giftData && this.giftData.id) {
                http.patch('gift_other', this.giftData)
                    .then((response) => {
                        this.$emit('newDataGifts', response.data)
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Saved',
                            color: 'success',
                        })
                        this.clearForm()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else if (this.userId) {
                this.giftData.user = this.userId
                http.post('gift_other', this.giftData)
                    .then((response) => {
                        this.$emit('newDataGifts', response.data)
                        this.$emit('close')
                        this.$vs.notify({
                            text: 'Saved',
                            color: 'success',
                        })
                        this.clearForm()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        setPerson(value) {
            if (value) this.giftData.beneficiary = value.id
            else this.giftData.beneficiary = null
        },
        setShare(value) {
            if (value) this.giftData.beneficiary_group_share = value.value
            else this.giftData.beneficiary_group_share = null
        },
        clearForm() {
            this.giftData.id = null
            this.giftData.user = null
            this.giftData.beneficiary = null
            this.giftData.beneficiary_group = null
            this.giftData.beneficiary_group_share = null
            this.giftData.item = null
            this.giftData.gift_before_partner = false
            this.giftData.gift_after_partner = false
        },
    },
}
</script>
