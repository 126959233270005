var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-prompt',{staticClass:"email-compose",attrs:{"title":"Discretionary Trust of Residue","accept-text":"Save","is-valid":true,"active":_vm.showModal},on:{"cancel":_vm.close,"accept":_vm.save,"close":_vm.close,"update:active":function($event){_vm.showModal=$event}}},[_c('VuePerfectScrollbar',{key:_vm.$vs.rtl,staticClass:"scroll-area p-4",attrs:{"settings":_vm.settings}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full sm:w-full md:w-full lg:w-full xl:w-full"},[_c('div',{staticClass:"flex mb-6"},[_c('label',{staticClass:"mr-auto",attrs:{"for":""}},[_vm._v("Enabled")]),_c('vs-switch',{attrs:{"color":"success","vs-icon":"done"},model:{value:(_vm.details.discretionary_trust),callback:function ($$v) {_vm.$set(_vm.details, "discretionary_trust", $$v)},expression:"details.discretionary_trust"}})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.details.discretionary_trust),expression:"details.discretionary_trust"}],staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-3"},[_c('div',{staticClass:"flex mb-6"},[_c('label',{staticClass:"mr-auto",attrs:{"for":""}},[_vm._v("Use Existing Trustee")]),_c('vs-switch',{attrs:{"color":"success","vs-icon":"done"},model:{value:(
                            _vm.details.discretionary_trust_existing_trustees
                        ),callback:function ($$v) {_vm.$set(_vm.details, "discretionary_trust_existing_trustees", $$v)},expression:"\n                            details.discretionary_trust_existing_trustees\n                        "}})],1)]),_c('div',{staticClass:"flex flex-wrap-reverse items-center flex-grow justify-end"},[_c('vs-dropdown',{staticClass:"cursor-pointer mb-4 mr-4 items-per-page-handler",attrs:{"vs-trigger-click":""}},[_c('div',{staticClass:"flex"},[_c('feather-icon',{attrs:{"icon":"PlusIcon","svgClasses":"h-4 w-4 text-success"}})],1),_c('vs-dropdown-menu',{staticClass:"w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6"},[_c('vs-dropdown-item',{on:{"click":function($event){_vm.show.personModal = true}}},[_vm._v("New Person")]),_c('vs-dropdown-item',{on:{"click":function($event){_vm.show.groupModal = true}}},[_vm._v("New Group")])],1)],1)],1),_c('div',{staticClass:"vx-col w-full mb-base"},[_c('vs-table',{attrs:{"noDataText":""}},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("Person/Group")]),_c('vs-th'),_c('vs-th',[_vm._v("Trustee")]),_c('vs-th',[_vm._v("Beneficiary")]),_c('vs-th',[_vm._v("Percentage")])],1),[_vm._l((_vm.people),function(person,index){return _c('vs-tr',{key:index + 'person'},[_c('vs-td',[_vm._v(" "+_vm._s(person.full_name)),_c('br')]),_c('vs-td',[_vm._v(" "+_vm._s(person.relationship)+" ")]),_c('vs-td',[_c('vs-checkbox',{attrs:{"disabled":_vm.details.discretionary_trust_existing_trustees},model:{value:(
                                        person.discretionary_trust_trustee
                                    ),callback:function ($$v) {_vm.$set(person, "discretionary_trust_trustee", $$v)},expression:"\n                                        person.discretionary_trust_trustee\n                                    "}})],1),_c('vs-td',[_c('vs-checkbox',{model:{value:(
                                        person.discretionary_trust_beneficiary
                                    ),callback:function ($$v) {_vm.$set(person, "discretionary_trust_beneficiary", $$v)},expression:"\n                                        person.discretionary_trust_beneficiary\n                                    "}})],1),_c('vs-td',[_c('vs-input',{staticClass:"w-2/3",attrs:{"type":"text"},model:{value:(
                                        person.discretionary_trust_beneficiary_percentage
                                    ),callback:function ($$v) {_vm.$set(person, "discretionary_trust_beneficiary_percentage", $$v)},expression:"\n                                        person.discretionary_trust_beneficiary_percentage\n                                    "}})],1)],1)}),_vm._l((_vm.groups),function(group,index){return _c('vs-tr',{key:index + 'group'},[_c('vs-td',[_vm._v(" "+_vm._s(_vm.capitalise(group.group_name))),_c('br')]),_c('vs-td'),_c('vs-td',[_c('vs-checkbox',{attrs:{"disabled":_vm.details.discretionary_trust_existing_trustees},model:{value:(
                                        group.discretionary_trust_trustee
                                    ),callback:function ($$v) {_vm.$set(group, "discretionary_trust_trustee", $$v)},expression:"\n                                        group.discretionary_trust_trustee\n                                    "}})],1),_c('vs-td',[_c('vs-checkbox',{model:{value:(
                                        group.discretionary_trust_beneficiary
                                    ),callback:function ($$v) {_vm.$set(group, "discretionary_trust_beneficiary", $$v)},expression:"\n                                        group.discretionary_trust_beneficiary\n                                    "}})],1),_c('vs-td',[_c('vs-input',{staticClass:"w-2/3",attrs:{"type":"text"},model:{value:(
                                        group.discretionary_trust_beneficiary_percentage
                                    ),callback:function ($$v) {_vm.$set(group, "discretionary_trust_beneficiary_percentage", $$v)},expression:"\n                                        group.discretionary_trust_beneficiary_percentage\n                                    "}})],1)],1)})]],2)],1)])]),_c('PersonModal',{attrs:{"show":_vm.show.personModal,"people":_vm.peopleData,"userId":_vm.userId},on:{"close":_vm.closePersonModal}}),_c('GroupModal',{attrs:{"show":_vm.show.groupModal,"userId":_vm.userId},on:{"close":_vm.closeGroupModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }