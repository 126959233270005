<template>
    <div :class="{ flex: !horizontal }" class="mb-3">
        <label for class="mr-auto vs-input--label">
            <span v-if="selected">Group Share</span>
        </label>
        <v-select
            :class="horizontal ? 'w-full' : 'w-1/2'"
            v-model="selected"
            :options="shareOptions"
            placeholder="Select Share"
        ></v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { http } from '@/services'

export default {
    name: 'GroupShareOptions',
    components: {
        vSelect,
    },
    props: ['shareData', 'horizontal'],
    watch: {
        selected(value) {
            this.$emit('selectedShare', value)
        },
        shareData: {
            immediate: true,
            handler(value) {
                this.selected = this.shareOptions.find(
                    (option) => option.value === value
                )
            },
        },
        personData: {
            immediate: true,
            handler(value) {
                if (value)
                    this.selected = this.people.find(
                        (person) => person.id === value
                    )
            },
        },
    },
    data() {
        return {
            shareOptions: [
                {
                    value: 'each',
                    label: 'Each',
                },
                {
                    value: 'shared',
                    label: 'Shared',
                },
            ],
            selected: '',
        }
    },
}
</script>
