<template>
    <vx-card
        title="Will Content"
        subtitle="Sections Gifts Trusts"
        collapse-action
        startCollapsed
    >
        <vs-divider color="success">Will Sections</vs-divider>
        <vs-list>
            <vs-list-item
                title="Executors"
                :subtitle="
                    details.executor_professional
                        ? 'Professional'
                        : 'User selected'
                "
            >
                <div class="ml-auto cursor-pointer">
                    <vs-button
                        :disabled="locked"
                        v-on:click="edit"
                        radius
                        type="flat"
                        icon-pack="feather"
                        icon="icon-edit-2"
                    />
                </div>
            </vs-list-item>
            <vs-list-item
                title="Trustees"
                :subtitle="
                    details.same_as_executors
                        ? 'Same as executors'
                        : 'User selected'
                "
            ></vs-list-item>
            <vs-list-item
                title="Pets"
                :subtitle="
                    details.pets_yes_no
                        ? 'Pets section included'
                        : 'No pets section'
                "
            ></vs-list-item>
            <vs-list-item
                title="Distribution"
                :subtitle="distributionDescription"
            ></vs-list-item>

            <vs-list-item title="Funeral" :subtitle="funeralDescription">
                <div class="ml-auto cursor-pointer">
                    <vs-button
                        v-on:click="editFuneral"
                        :disabled="locked"
                        radius
                        type="flat"
                        icon-pack="feather"
                        icon="icon-edit-2"
                    />
                </div>
            </vs-list-item>
        </vs-list>

        <vs-divider color="success">Trusts</vs-divider>
        <div class="flex flex-row-reverse">
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div class="flex">
                    <span class="mr-2">Add</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu
                    class="w-full sm:w-full md:w-1/6 lg:w-1/6 xl:w-1/6"
                >
                    <vs-dropdown-item @click="addLifeTrust"
                        >Life Interest Trust</vs-dropdown-item
                    >
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>
        <vs-list>
            <vs-list-item title="Home Protection">
                <div class="ml-auto cursor-pointer">
                    <vs-button
                        v-on:click="show.homeProtectionModal = true"
                        :disabled="locked"
                        radius
                        type="flat"
                        icon-pack="feather"
                        :icon="
                            details.home_protection
                                ? 'icon-edit-2'
                                : 'icon-plus'
                        "
                    />
                </div>
            </vs-list-item>
            <vs-list-item title="Discretionary Trust of Residue">
                <div class="ml-auto cursor-pointer">
                    <vs-button
                        v-on:click="show.discretionaryTrustModal = true"
                        :disabled="locked"
                        radius
                        type="flat"
                        icon-pack="feather"
                        :icon="
                            details.discretionary_trust
                                ? 'icon-edit-2'
                                : 'icon-plus'
                        "
                    />
                </div>
            </vs-list-item>
            <vs-list-item title="Disability Trust">
                <div class="ml-auto cursor-pointer">
                    <vs-button
                        v-on:click="show.disabilityTrustModal = true"
                        :disabled="locked"
                        radius
                        type="flat"
                        icon-pack="feather"
                        :icon="
                            details.disability_trust
                                ? 'icon-edit-2'
                                : 'icon-plus'
                        "
                    />
                </div>
            </vs-list-item>
            <div v-if="trusts && trusts.life && trusts.life.length">
                <vs-list-item
                    v-for="(lifeTrust, index) in trusts.life"
                    :key="index"
                    :title="lifeTrustTitle(lifeTrust)"
                >
                    <div class="ml-auto cursor-pointer flex">
                        <vs-button
                            v-on:click="deleteLifeTrustConfirm(lifeTrust)"
                            :disabled="locked"
                            radius
                            color="danger"
                            type="flat"
                            icon-pack="feather"
                            icon="icon-trash"
                        />
                        <vs-button
                            v-on:click="editLifeTrust(lifeTrust)"
                            :disabled="locked"
                            radius
                            type="flat"
                            icon-pack="feather"
                            icon="icon-edit-2"
                        />
                    </div>
                </vs-list-item>
            </div>
        </vs-list>

        <vs-divider color="success">Gifts</vs-divider>
        <div class="flex flex-row-reverse">
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div class="flex">
                    <span class="mr-2">Add</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu
                    class="w-full sm:w-full md:w-1/6 lg:w-1/6 xl:w-1/6"
                >
                    <vs-dropdown-item
                        @click="
                            selectedCashGift = null
                            show.cashGiftModal = true
                        "
                        >Cash</vs-dropdown-item
                    >
                    <vs-dropdown-item
                        @click="
                            selectedCharityGift = null
                            show.charityGiftModal = true
                        "
                        >Charity
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="
                            selectedPropertyGift = null
                            show.propertyGiftModal = true
                        "
                        >Property
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        @click="
                            selectedOtherGift = null
                            show.otherGiftModal = true
                        "
                        >Other</vs-dropdown-item
                    >
                    <vs-dropdown-item
                        @click="
                            selectedVehicleGift = null
                            show.vehicleGiftModal = true
                        "
                        >Vehicle
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <vs-list>
            <vs-list-item
                v-for="(gift, index) in gifts.cash"
                :key="index + 'cash'"
                title="Cash Gift"
                :subtitle="
                    '£' +
                    gift.amount +
                    ' to ' +
                    (gift.beneficiary_group ? gift.beneficiary_group : gift.beneficiary && gift.beneficiary.full_name ? gift.beneficiary.full_name : '')
                "
            >
                <div class="ml-auto cursor-pointer">
                    <div class="flex">
                        <vs-button
                            v-on:click="confirmDeleteCashGift(gift)"
                            :disabled="locked"
                            radius
                            type="flat"
                            color="secondary"
                            icon-pack="feather"
                            icon="icon-trash"
                        />
                        <vs-button
                            v-on:click="editCashGift(gift)"
                            :disabled="locked"
                            radius
                            type="flat"
                            icon-pack="feather"
                            icon="icon-edit-2"
                        />
                    </div>
                </div>
            </vs-list-item>
            <vs-list-item
                v-for="(gift, index) in gifts.charity"
                :key="index + 'charity'"
                title="Charity Gift"
                :subtitle="'£' + gift.amount + ' to ' + gift.charity_name"
            >
                <div class="ml-auto cursor-pointer">
                    <div class="flex">
                        <vs-button
                            v-on:click="confirmDeleteCharityGift(gift)"
                            :disabled="locked"
                            radius
                            type="flat"
                            color="secondary"
                            icon-pack="feather"
                            icon="icon-trash"
                        />
                        <vs-button
                            v-on:click="editCharityGift(gift)"
                            :disabled="locked"
                            radius
                            type="flat"
                            icon-pack="feather"
                            icon="icon-edit-2"
                        />
                    </div>
                </div>
            </vs-list-item>

            <vs-list-item
                v-for="(gift, index) in gifts.real_estate"
                :key="index + 'property'"
                title="Property Gift"
                :subtitle="
                    gift.address_line_1 +
                    ' to ' +
                    (gift.beneficiary_group ? gift.beneficiary_group : gift.beneficiary && gift.beneficiary.full_name ? gift.beneficiary.full_name : '')
                "
            >
                <div class="ml-auto cursor-pointer">
                    <div class="flex">
                        <vs-button
                            v-on:click="confirmDeletePropertyGift(gift)"
                            :disabled="locked"
                            radius
                            type="flat"
                            color="secondary"
                            icon-pack="feather"
                            icon="icon-trash"
                        />
                        <vs-button
                            v-on:click="editPropertyGift(gift)"
                            :disabled="locked"
                            radius
                            type="flat"
                            icon-pack="feather"
                            icon="icon-edit-2"
                        />
                    </div>
                </div>
            </vs-list-item>

            <vs-list-item
                v-for="(gift, index) in gifts.vehicle"
                :key="index + 'vehicle'"
                title="Vehicle Gift"
                :subtitle="
                    gift.make +
                    ' ' +
                    gift.model +
                    ' to ' +
                    (gift.beneficiary_group ? gift.beneficiary_group : gift.beneficiary && gift.beneficiary.full_name ? gift.beneficiary.full_name : '')
                "
            >
                <div class="ml-auto cursor-pointer">
                    <div class="flex">
                        <vs-button
                            v-on:click="confirmDeleteVehicleGift(gift)"
                            :disabled="locked"
                            radius
                            type="flat"
                            color="secondary"
                            icon-pack="feather"
                            icon="icon-trash"
                        />

                        <vs-button
                            v-on:click="editVehicleGift(gift)"
                            :disabled="locked"
                            radius
                            type="flat"
                            icon-pack="feather"
                            icon="icon-edit-2"
                        />
                    </div>
                </div>
            </vs-list-item>

            <vs-list-item
                v-for="(gift, index) in gifts.other"
                :key="index + 'other'"
                title="Other Gift"
                :subtitle="
                    gift.item +
                    ' to ' +
                    (gift.beneficiary_group ? gift.beneficiary_group : gift.beneficiary && gift.beneficiary.full_name ? gift.beneficiary.full_name : '')
                "
            >
                <div class="ml-auto cursor-pointer">
                    <div class="flex">
                        <vs-button
                            v-on:click="confirmDeleteOtherGift(gift)"
                            :disabled="locked"
                            radius
                            type="flat"
                            color="secondary"
                            icon-pack="feather"
                            icon="icon-trash"
                        />
                        <vs-button
                            v-on:click="editOtherGift(gift)"
                            :disabled="locked"
                            radius
                            type="flat"
                            icon-pack="feather"
                            icon="icon-edit-2"
                        />
                    </div>
                </div>
            </vs-list-item>
        </vs-list>

        <StructureModal
            :details="details"
            :show="show.structureModal"
            @close="closeStructureModal"
            @changed="$emit('changed')"
            @newData="$emit('newData', $event)"
        ></StructureModal>
        <FuneralModal
            :funeral="funeral"
            :show="show.funeralModal"
            @close="closeFuneralModal"
            @changed="$emit('changed')"
            @newData="$emit('newDataFuneral', $event)"
        ></FuneralModal>
        <OtherGiftModal
            :gift="selectedOtherGift"
            :show="show.otherGiftModal"
            :peopleData="peopleData"
            :userId="details.user"
            @close="show.otherGiftModal = false"
            @newDataGifts="$emit('newDataGifts', $event)"
        />

        <VehicleGiftModal
            :gift="selectedVehicleGift"
            :show="show.vehicleGiftModal"
            :peopleData="peopleData"
            :userId="details.user"
            @close="show.vehicleGiftModal = false"
            @newDataGifts="$emit('newDataGifts', $event)"
        />
        <CashGiftModal
            :gift="selectedCashGift"
            :show="show.cashGiftModal"
            :peopleData="peopleData"
            :userId="details.user"
            @close="show.cashGiftModal = false"
            @newDataGifts="$emit('newDataGifts', $event)"
        />

        <CharityGiftModal
            :gift="selectedCharityGift"
            :show="show.charityGiftModal"
            :peopleData="peopleData"
            :userId="details.user"
            @close="show.charityGiftModal = false"
            @newDataGifts="$emit('newDataGifts', $event)"
        />

        <PropertyGiftModal
            :gift="selectedPropertyGift"
            :show="show.propertyGiftModal"
            :peopleData="peopleData"
            :userId="details.user"
            @close="show.propertyGiftModal = false"
            @newDataGifts="$emit('newDataGifts', $event)"
        />

        <HomeProtectionModal
            :showData="show.homeProtectionModal"
            :detailsData="details"
            :peopleData="peopleData"
            @close="show.homeProtectionModal = false"
            @refresh="$emit('refreshDetails')"
            @peopleListFetch="$emit('peopleListFetch')"
        />

        <DiscretionaryTrustModal
            :showData="show.discretionaryTrustModal"
            :detailsData="details"
            :peopleData="peopleData"
            :groupsData="groupsData"
            @close="show.discretionaryTrustModal = false"
            @refresh="$emit('refreshDetails')"
            @peopleListFetch="$emit('peopleListFetch')"
        />
        <DisabilityTrustModal
            :showData="show.disabilityTrustModal"
            :detailsData="details"
            :peopleData="peopleData"
            :groupsData="groupsData"
            @close="show.disabilityTrustModal = false"
            @refresh="$emit('refreshDetails')"
            @peopleListFetch="$emit('peopleListFetch')"
        />
        <LifeInterestTrustModal
            :showData="show.lifeInterestTrust"
            :trustData="selected.lifeTrust"
            :peopleData="peopleData"
            :userId="details.user"
            @close="show.lifeInterestTrust = false"
            @refresh="$emit('refreshTrusts')"
            @peopleListFetch="$emit('peopleListFetch')"
        />
    </vx-card>
</template>

<script>
import { http } from '@/services'
import StructureModal from '../Modals/StructureModal'
import FuneralModal from '../Modals/FuneralModal'
import OtherGiftModal from '../Modals/Gifts/OtherGiftModal'
import VehicleGiftModal from '../Modals/Gifts/VehicleGiftModal'
import CashGiftModal from '../Modals/Gifts/CashGiftModal'
import CharityGiftModal from '../Modals/Gifts/CharityGiftModal'
import PropertyGiftModal from '../Modals/Gifts/PropertyGiftModal'

import HomeProtectionModal from '../Modals/HomeProtection'
import DiscretionaryTrustModal from '../Modals/DiscretionaryTrust'
import DisabilityTrustModal from '../Modals/DisabilityTrust'
import LifeInterestTrustModal from '@/views/pages/Wills/Will/Modals/LifeInterestTrust'

export default {
    name: 'StructureCard',
    props: [
        'details',
        'funeral',
        'gifts',
        'locked',
        'peopleData',
        'groupsData',
        'trusts',
    ],
    components: {
        StructureModal,
        FuneralModal,
        OtherGiftModal,
        VehicleGiftModal,
        HomeProtectionModal,
        CashGiftModal,
        CharityGiftModal,
        PropertyGiftModal,
        DiscretionaryTrustModal,
        DisabilityTrustModal,
        LifeInterestTrustModal,
    },
    computed: {
        distributionDescription() {
            var string = ''
            if (this.details.distribution_minor_18) string += '* Minors at 18 '
            if (this.details.distribution_minor_21) string += '* Minors at 21 '
            if (this.details.distribution_minor_25) string += '* Minors at 25 '
            if (this.details.distribution_all_die_backup_table)
                string += '* Substitutional Provisions Allowed'
            else string += '* Substitutional Provisions section not included'
            return string
        },
        funeralDescription() {
            var string = ''
            if (this.funeral.funeral_finish)
                string += '* ' + this.funeral.funeral_finish + ' '
            if (this.funeral.funeral_type_celebration)
                string += '* Celebreation '
            if (this.funeral.funeral_type_simple) string += '* Simple '
            if (this.funeral.funeral_type_green) string += '* Green '
            if (this.funeral.funeral_type_grand) string += '* Grand '
            if (this.funeral.funeral_type_non_religious)
                string += '* Non religious '
            if (this.funeral.funeral_type_no_preference)
                string += '* No Preference '
            if (this.funeral.funeral_wishes)
                string += '* Funeral wishes provided '
            return string
        },
    },
    data() {
        return {
            show: {
                structureModal: false,
                funeralModal: false,
                otherGiftModal: false,
                vehicleGiftModal: false,
                charityGiftModal: false,
                cashGiftModal: false,
                propertyGiftModal: false,
                homeProtectionModal: false,
                discretionaryTrustModal: false,
                disabilityTrustModal: false,
                lifeInterestTrust: false,
            },
            selectedOtherGift: null,
            selectedVehicleGift: null,
            selectedCashGift: null,
            selectedCharityGift: null,
            selectedPropertyGift: null,
            selected: {
                lifeTrust: null,
            },
        }
    },
    methods: {
        edit() {
            this.show.structureModal = true
        },
        editFuneral() {
            this.show.funeralModal = true
        },
        closeStructureModal() {
            this.show.structureModal = false
        },
        closeFuneralModal() {
            this.show.funeralModal = false
        },
        editCashGift(value) {
            this.show.cashGiftModal = true
            this.selectedCashGift = value
        },
        editCharityGift(value) {
            this.show.charityGiftModal = true
            this.selectedCharityGift = value
        },
        editVehicleGift(value) {
            this.show.vehicleGiftModal = true
            this.selectedVehicleGift = value
        },
        editPropertyGift(value) {
            this.show.propertyGiftModal = true
            this.selectedPropertyGift = value
        },
        editOtherGift(value) {
            this.show.otherGiftModal = true
            this.selectedOtherGift = value
        },
        confirmDeleteVehicleGift(value) {
            this.selectedVehicleGift = value
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Delete Vehicle Gift`,
                text: `${this.selectedVehicleGift.make} ${this.selectedVehicleGift.model}`,
                accept: this.deleteVehicleGift,
                acceptText: 'Delete',
            })
        },
        confirmDeleteCashGift(value) {
            this.selectedCashGift = value
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Delete Cash Gift`,
                text: `£${this.selectedCashGift.amount}`,
                accept: this.deleteCashGift,
                acceptText: 'Delete',
            })
        },
        confirmDeleteCharityGift(value) {
            this.selectedCharityGift = value
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Delete Charity Gift`,
                text: `${this.selectedCharityGift.charity_name}`,
                accept: this.deleteCharityGift,
                acceptText: 'Delete',
            })
        },
        confirmDeletePropertyGift(value) {
            this.selectedPropertyGift = value
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Delete Property Gift`,
                text: `${this.selectedPropertyGift.address_line_1} ${this.selectedPropertyGift.post_town}`,
                accept: this.deletePropertyGift,
                acceptText: 'Delete',
            })
        },
        confirmDeleteOtherGift(value) {
            this.selectedOtherGift = value
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Delete Other Gift`,
                text: `${this.selectedOtherGift.item}`,
                accept: this.deleteOtherGift,
                acceptText: 'Delete',
            })
        },
        deleteVehicleGift(value) {
            http.delete('gift_vehicle', {
                params: { id: this.selectedVehicleGift.id },
            })
                .then((response) => {
                    this.$emit('newDataGifts', response.data)
                    this.$vs.notify({
                        text: 'Deleted',
                        color: 'primary',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'secondary',
                    })
                })
        },
        deleteOtherGift(value) {
            http.delete('gift_other', {
                params: { id: this.selectedOtherGift.id },
            })
                .then((response) => {
                    this.$emit('newDataGifts', response.data)
                    this.$vs.notify({
                        text: 'Deleted',
                        color: 'primary',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'secondary',
                    })
                })
        },
        deleteCharityGift(value) {
            http.delete('gift_charity', {
                params: { id: this.selectedCharityGift.id },
            })
                .then((response) => {
                    this.$emit('newDataGifts', response.data)
                    this.$vs.notify({
                        text: 'Deleted',
                        color: 'primary',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'secondary',
                    })
                })
        },
        deletePropertyGift(value) {
            http.delete('gift_property', {
                params: { id: this.selectedPropertyGift.id },
            })
                .then((response) => {
                    this.$emit('newDataGifts', response.data)
                    this.$vs.notify({
                        text: 'Deleted',
                        color: 'primary',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'secondary',
                    })
                })
        },
        deleteCashGift(value) {
            http.delete('gift_cash', {
                params: { id: this.selectedCashGift.id },
            })
                .then((response) => {
                    this.$emit('newDataGifts', response.data)
                    this.$vs.notify({
                        text: 'Deleted',
                        color: 'primary',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'secondary',
                    })
                })
        },
        person(personId) {
            if (personId) {
                let person = this.peopleData.find(
                    (person) => person.id === personId
                )
                if (person) return person.full_name
            }
            return 'NO PERSON SET'
        },
        editLifeTrust(trust) {
            this.selected.lifeTrust = trust
            this.show.lifeInterestTrust = true
        },
        addLifeTrust() {
            this.selected.lifeTrust = null
            this.show.lifeInterestTrust = true
        },
        deleteLifeTrustConfirm(trust) {
            this.selected.lifeTrust = trust
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Delete`,
                text: 'Direct life interest trust',
                accept: this.deleteLifeTrust,
                acceptText: 'Send',
            })
        },
        deleteLifeTrust() {
            http.delete('life_interest_trust_detail', {
                params: { id: this.selected.lifeTrust.id },
            })
                .then((response) => {
                    this.$emit('refreshTrusts')
                    this.$vs.notify({
                        title: 'Deleted',
                        text: 'Life trust removed',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Error',
                        text: error,
                        color: 'danger',
                    })
                })
        },
        lifeTrustTitle(trust) {
            var title = 'Life Interest Trust'
            if (trust && trust.life_tenant) {
                var person = this.peopleData.find(
                    (person) => person.id === trust.life_tenant
                )
                if (person) title += ` for ${person.full_name}`
            }
            return title
        },
    },
}
</script>
