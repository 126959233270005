<template>
    <SideBarModal title="Funeral" :show="show" @close="$emit('close')">
        <template v-slot:content>
            <p class="text-xs">Funeral type</p>
            <vs-select v-model="funeralData.funeral_finish" class="mb-3">
                <vs-select-item text="Buried" value="Buried" />
                <vs-select-item text="Cremated" value="Cremated" />
                <vs-select-item text="No preference" value="No Preference" />
            </vs-select>

            <p class="text-xs">Organs Options</p>
            <vs-select v-model="funeralData.funeral_organs" class="mb-3">
                <vs-select-item
                    text="Yes, for therapy"
                    value="Yes, for therapy"
                />
                <vs-select-item
                    text="Yes, for research"
                    value="Yes, for research"
                />
                <vs-select-item
                    text="Yes, for research & therapy"
                    value="Yes, for research & therapy"
                />
                <vs-select-item text="No" value="No" />
            </vs-select>

            <vs-checkbox
                v-model="funeralData.funeral_type_celebration"
                class="mb-2"
                >Celebration</vs-checkbox
            >
            <vs-checkbox v-model="funeralData.funeral_type_simple" class="mb-2"
                >Simple</vs-checkbox
            >
            <vs-checkbox v-model="funeralData.funeral_type_green" class="mb-2"
                >Green</vs-checkbox
            >
            <vs-checkbox v-model="funeralData.funeral_type_grand" class="mb-2"
                >Grand</vs-checkbox
            >
            <vs-checkbox
                v-model="funeralData.funeral_type_non_religious"
                class="mb-2"
                >Non Religious</vs-checkbox
            >
            <vs-checkbox
                v-model="funeralData.funeral_type_no_preference"
                class="mb-2"
                >No Preference</vs-checkbox
            >

            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-textarea
                        v-model="funeralData.funeral_wishes"
                        label="Funeral wishes"
                    />
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <vs-button class="mr-6" @click="funeralSave">Save</vs-button>
            <vs-button type="border" color="danger" @click="$emit('close')"
                >Cancel</vs-button
            >
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'

import SideBarModal from '@/components/common/SideBarModal'

export default {
    name: 'FuneralModal',
    components: {
        SideBarModal,
    },
    props: ['funeral', 'show'],
    watch: {
        funeral: {
            deep: true,
            immediate: true,
            handler(data) {
                this.funeralData = { ...data }
            },
        },
    },
    data() {
        return {
            funeralData: null,
        }
    },
    computed: {},
    methods: {
        funeralSave() {
            if (this.funeralData && this.funeralData.id) {
                http.patch('funeral', this.funeralData)
                    .then((response) => {
                        this.$emit('newData', response.data)
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Saved',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
    },
}
</script>
