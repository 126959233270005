<template>
    <vs-prompt
        class="email-compose"
        :title="title"
        accept-text="Save"
        @cancel="close"
        @accept="save"
        @close="close"
        :is-valid="true"
        :active.sync="showModal"
    >
        <VuePerfectScrollbar
            v-if="form"
            class="scroll-area p-4"
            :settings="settings"
            :key="$vs.rtl"
        >
            <h5 class="mb-3">Trust For</h5>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <PersonSelect
                        :personData="form.life_tenant"
                        :peopleData="peopleData"
                        label="Disabled Person"
                        horizontal="true"
                        @selectedPerson="setPerson"
                    />
                </div>
            </div>
            <h5 class="mb-3">Property or Other item</h5>

            <div class="vx-row">
                <div class="vx-col w-full md:w-1/2">
                    <vs-input
                        v-model="form.address_line_1"
                        label-placeholder="Address Line 1"
                        class="w-full"
                    />
                    <vs-input
                        v-model="form.address_line_2"
                        label-placeholder="Address Line 2"
                        class="w-full mt-6"
                    />
                </div>

                <div class="vx-col w-full md:w-1/2">
                    <vs-input
                        v-model="form.post_town"
                        label-placeholder="Post Town"
                        class="w-full"
                    />
                    <vs-input
                        v-model="form.post_code"
                        label-placeholder="Post Code"
                        class="w-full mt-6"
                    />
                </div>
            </div>
            <div class="vx-row mt-3">
                <div class="vx-col w-full">
                    <vs-input
                        class="w-full"
                        v-model="form.other"
                        label-placeholder="Other Item"
                    />
                </div>
            </div>

            <h5 class="mt-3">Details</h5>

            <div class="vx-row mt-2">
                <div class="vx-col w-full md:w-1/2">
                    <vs-input
                        v-model="form.period"
                        label-placeholder="Trust Period"
                        class="mb-6 w-full"
                    />
                </div>
                <div class="vx-col w-full md:w-1/2">
                    <vs-input
                        v-model="form.children_age"
                        type="number"
                        label-placeholder="Children Age"
                        class="w-full"
                    ></vs-input>
                </div>
            </div>

            <h5 class="mt-3 mb-3">Trustees</h5>

            <div class="vx-col w-full mb-base">
                <vs-table :data="people">
                    <template slot="thead">
                        <vs-th>Person</vs-th>
                        <vs-th>Trustee</vs-th>
                    </template>

                    <template>
                        <vs-tr :key="index" v-for="(person, index) in people">
                            <vs-td>
                                {{ person.full_name }}<br />
                                {{ person.relationship }}
                            </vs-td>

                            <vs-td>
                                <vs-checkbox
                                    v-model="form.trustees"
                                    :vs-value="person.id"
                                ></vs-checkbox>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </VuePerfectScrollbar>
        <PersonModal
            :show="show.personModal"
            :people="peopleData"
            :userId="userId"
            @close="closePersonModal"
        ></PersonModal>
    </vs-prompt>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import PersonSelect from '@/components/common/PersonSelect'
import PersonModal from '../Modals/PersonModal'

export default {
    name: 'LifeInterestTrustModal',
    props: ['showData', 'trustData', 'peopleData', 'userId'],
    mixins: [textFormat],
    components: {
        VuePerfectScrollbar,
        PersonSelect,
        PersonModal,
    },
    watch: {
        trustData: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value) {
                    this.form = Object.assign({}, value)
                }
            },
        },
        peopleData: {
            immediate: true,
            deep: true,
            handler(value) {
                this.people = value.map((person) => ({
                    id: person.id,
                    full_name: person.full_name,
                    relationship: person.relationship,
                    trustee: false,
                }))
            },
        },
    },
    computed: {
        lifeTenant() {
            if (this.form && this.form.life_tenant && this.people) {
                return this.people.find(
                    (person) => person.id === this.form.life_tenant
                )
            }
            return null
        },
        title() {
            var title = 'Life Interest Trust'
            if (this.lifeTenant) {
                title += ' for ' + this.lifeTenant.full_name
            }
            return title
        },
        showModal: {
            set(value) {},
            get() {
                return this.showData
            },
        },
    },
    data() {
        return {
            form: {
                id: null,
                address_line_1: null,
                address_line_2: null,
                post_town: null,
                post_code: null,
                other: null,
                life_tenant: null,
                trustees: [],
                period: 10,
                children_age: 18,
            },
            people: null,
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            show: {
                personModal: false,
                groupModal: false,
            },
        }
    },
    methods: {
        save() {
            if (this.form.id) {
                this.patch()
            } else if (this.userId) {
                this.post()
            }
        },
        post() {
            this.form.user = this.userId
            http.post('life_interest_trust_detail', this.form)
                .then((response) => {
                    this.$vs.notify({
                        text: 'Saved',
                        color: 'success',
                    })
                    this.close()
                    this.$emit('refresh')
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error,
                        color: 'danger',
                    })
                })
        },
        patch() {
            http.patch('life_interest_trust_detail', this.form)
                .then((response) => {
                    this.$vs.notify({
                        text: 'Saved',
                        color: 'success',
                    })
                    this.close()
                    this.$emit('refresh')
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error,
                        color: 'danger',
                    })
                })
        },
        cancel() {
            this.form = null
            this.$emit('close')
        },
        close() {
            this.$emit('close')
            this.clearForm()
        },
        clearForm() {
            this.form.id = null
            this.form.address_line_1 = null
            this.form.address_line_2 = null
            this.form.post_town = null
            this.form.post_code = null
            this.form.other = null
            this.form.life_tenant = null
            this.form.trustees = []
            this.form.period = 10
            this.form.children_age = 18
        },
        setPerson(value) {
            if (value) this.form.life_tenant = value.id
        },
        setDuration(value) {
            this.details.home_protection_duration = value.value
        },
        closePersonModal() {
            this.$emit('peopleListFetch')
            this.show.personModal = false
        },
        closeGroupModal() {
            this.$emit('peopleListFetch')
            this.show.groupModal = false
        },
    },
}
</script>
