<template>
    <vs-popup fullscreen :title="title" :active="show" @close="$emit('close')">
        <p>
            <vs-chip color="secondary">Removed from will</vs-chip>
            <vs-chip color="success">Added to will</vs-chip>
        </p>
        <iframe :srcdoc="compare" style="width: 100%"> </iframe>
    </vs-popup>
</template>

<script>
var Diff = require('diff')
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'
export default {
    name: 'CompareModal',
    props: ['show', 'compareWith', 'thisWill', 'thisWillMeta'],
    mixins: [textFormat],
    watch: {
        compareWith: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.compareWillFetch()
                }
            },
        },
        thisWill: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.thisWillParsed = value
                }
            },
        },
    },
    computed: {
        title() {
            var string = ''
            if (this.thisWillMeta && this.compareWith) {
                string +=
                    'Comparing Will ' +
                    this.formatDateAndTime(this.thisWillMeta.uploaded_at) +
                    ' with ' +
                    this.formatDateAndTime(this.compareWith.uploaded_at)
            } else {
                string += 'Comapre Wills'
            }
            return string
        },
        compare() {
            if (this.thisWill && this.compareWithData) {
                var diff = Diff.diffWords(this.compareWithData, this.thisWill)
                var result = ''
                diff.forEach(function (part) {
                    if (part.added)
                        result += `<span style="color: white; background-color: #4f9b7e; border-radius: 20px; padding: 3px 10px 3px 10px">${part.value}</span>`
                    if (part.removed)
                        result += `<span style="color: white; background-color: #e56781; border-radius: 15px; padding: 3px 10px 3px 10px">${part.value}</span>`
                    else result += part.value
                })
                return result
            }
            return null
        },
    },
    data() {
        return {
            compareWithData: null,
            thisWillParsed: null,
        }
    },
    methods: {
        compareWillFetch() {
            http.get('will_html_only', { params: { id: this.compareWith.id } })
                .then((response) => {
                    this.compareWithData = response.data
                })
                .catch((error) => console.log(error))
        },
    },
}
</script>

<style scoped>
iframe {
    border: none;
    height: calc(100vh - 170px);
}
</style>
