<template>
    <vs-prompt
        class="email-compose"
        title="Discretionary Trust of Residue"
        accept-text="Save"
        @cancel="close"
        @accept="save"
        @close="close"
        :is-valid="true"
        :active.sync="showModal"
    >
        <VuePerfectScrollbar
            class="scroll-area p-4"
            :settings="settings"
            :key="$vs.rtl"
        >
            <div class="vx-row">
                <div
                    class="vx-col w-full sm:w-full md:w-full lg:w-full xl:w-full"
                >
                    <div class="flex mb-6">
                        <label for="" class="mr-auto">Enabled</label>
                        <vs-switch
                            v-model="details.discretionary_trust"
                            color="success"
                            vs-icon="done"
                        >
                        </vs-switch>
                    </div>
                </div>
            </div>

            <div v-show="details.discretionary_trust" class="vx-row">
                <div class="vx-col w-full mb-3">
                    <div class="flex mb-6">
                        <label for="" class="mr-auto"
                            >Use Existing Trustee</label
                        >
                        <vs-switch
                            v-model="
                                details.discretionary_trust_existing_trustees
                            "
                            color="success"
                            vs-icon="done"
                        >
                        </vs-switch>
                    </div>
                </div>

                <div
                    class="flex flex-wrap-reverse items-center flex-grow justify-end"
                >
                    <vs-dropdown
                        vs-trigger-click
                        class="cursor-pointer mb-4 mr-4 items-per-page-handler"
                    >
                        <div class="flex">
                            <feather-icon
                                icon="PlusIcon"
                                svgClasses="h-4 w-4 text-success"
                            />
                        </div>

                        <vs-dropdown-menu
                            class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6"
                        >
                            <vs-dropdown-item @click="show.personModal = true"
                                >New Person</vs-dropdown-item
                            >
                            <vs-dropdown-item @click="show.groupModal = true"
                                >New Group</vs-dropdown-item
                            >
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>

                <div class="vx-col w-full mb-base">
                    <vs-table noDataText="">
                        <template slot="thead">
                            <vs-th>Person/Group</vs-th>
                            <vs-th></vs-th>
                            <vs-th>Trustee</vs-th>
                            <vs-th>Beneficiary</vs-th>
                            <vs-th>Percentage</vs-th>
                        </template>

                        <template>
                            <vs-tr
                                :key="index + 'person'"
                                v-for="(person, index) in people"
                            >
                                <vs-td> {{ person.full_name }}<br /> </vs-td>
                                <vs-td>
                                    {{ person.relationship }}
                                </vs-td>

                                <vs-td>
                                    <vs-checkbox
                                        v-model="
                                            person.discretionary_trust_trustee
                                        "
                                        :disabled="
                                            details.discretionary_trust_existing_trustees
                                        "
                                    ></vs-checkbox>
                                </vs-td>

                                <vs-td>
                                    <vs-checkbox
                                        v-model="
                                            person.discretionary_trust_beneficiary
                                        "
                                    ></vs-checkbox>
                                </vs-td>

                                <vs-td>
                                    <vs-input
                                        class="w-2/3"
                                        type="text"
                                        v-model="
                                            person.discretionary_trust_beneficiary_percentage
                                        "
                                    />
                                </vs-td>
                            </vs-tr>

                            <vs-tr
                                :key="index + 'group'"
                                v-for="(group, index) in groups"
                            >
                                <vs-td>
                                    {{ capitalise(group.group_name) }}<br />
                                </vs-td>
                                <vs-td> </vs-td>

                                <vs-td>
                                    <vs-checkbox
                                        v-model="
                                            group.discretionary_trust_trustee
                                        "
                                        :disabled="
                                            details.discretionary_trust_existing_trustees
                                        "
                                    ></vs-checkbox>
                                </vs-td>

                                <vs-td>
                                    <vs-checkbox
                                        v-model="
                                            group.discretionary_trust_beneficiary
                                        "
                                    ></vs-checkbox>
                                </vs-td>

                                <vs-td>
                                    <vs-input
                                        class="w-2/3"
                                        type="text"
                                        v-model="
                                            group.discretionary_trust_beneficiary_percentage
                                        "
                                    />
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
        </VuePerfectScrollbar>
        <PersonModal
            :show="show.personModal"
            :people="peopleData"
            :userId="userId"
            @close="closePersonModal"
        ></PersonModal>
        <GroupModal
            :show="show.groupModal"
            :userId="userId"
            @close="closeGroupModal"
        ></GroupModal>
    </vs-prompt>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'

import PersonModal from '../Modals/PersonModal'
import GroupModal from '../Modals/GroupModal'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    name: 'DiscretionaryTrustModal',
    props: ['showData', 'detailsData', 'peopleData', 'groupsData'],
    mixins: [textFormat],
    components: {
        VuePerfectScrollbar,
        PersonModal,
        GroupModal,
    },
    watch: {
        detailsData: {
            immediate: true,
            handler(value) {
                this.details.id = value.id
                this.details.discretionary_trust = value.discretionary_trust
                this.details.discretionary_trust_existing_trustees =
                    value.discretionary_trust_existing_trustees
            },
        },
        peopleData: {
            immediate: true,
            handler(value) {
                this.people = value.map((person) => ({
                    id: person.id,
                    full_name: person.full_name,
                    relationship: person.relationship,
                    discretionary_trust_trustee:
                        person.discretionary_trust_trustee,
                    discretionary_trust_beneficiary:
                        person.discretionary_trust_beneficiary,
                    discretionary_trust_beneficiary_percentage:
                        person.discretionary_trust_beneficiary_percentage,
                }))
            },
        },
        groupsData: {
            immediate: true,
            handler(value) {
                this.groups = value.map((group) => ({
                    id: group.id,
                    group_name: group.group_name,
                    discretionary_trust_trustee:
                        group.discretionary_trust_trustee,
                    discretionary_trust_beneficiary:
                        group.discretionary_trust_beneficiary,
                    discretionary_trust_beneficiary_percentage:
                        group.discretionary_trust_beneficiary_percentage,
                }))
            },
        },
    },
    computed: {
        showModal: {
            set(value) {},
            get() {
                return this.showData
            },
        },
        userId() {
            if (this.detailsData) return this.detailsData.user
            return null
        },
    },
    data() {
        return {
            details: {
                id: null,
                discretionary_trust: null,
                discretionary_trust_existing_trustees: null,
            },
            people: null,
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            show: {
                personModal: false,
                groupModal: false,
            },
        }
    },
    methods: {
        save() {
            var data = {
                details: this.details,
                people: this.people,
                groups: this.groups,
            }
            http.patch('discretionary_trust', data)
                .then((response) => {
                    this.close()
                    this.$emit('refresh')
                    this.$vs.notify({
                        text: data.details.discretionary_trust
                            ? 'Discretionary Trust Added'
                            : 'Discretionary Trust Removed',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: error,
                        color: 'danger',
                    })
                })
        },
        cancel() {
            this.$emit('close')
        },
        close() {
            this.$emit('close')
        },
        closePersonModal() {
            this.$emit('peopleListFetch')
            this.show.personModal = false
        },
        closeGroupModal() {
            this.$emit('peopleListFetch')
            this.show.groupModal = false
        },
    },
}
</script>
