<template>
    <div v-if="data" class="flex flex-wrap justify-between">
        <div>
            <vs-button
                class=""
                color="primary"
                icon="icon-chevron-left"
                icon-pack="feather"
                radius
                type="flat"
                v-on:click="$emit('back')"
            ></vs-button>
        </div>

        <div class="flex justify-end flex-wrap">
            <div v-if="contactAdvice || contactIssue" class="mr-3">
                <datepicker
                    v-model="deferDate"
                    placeholder="Defer contact until"
                ></datepicker>
            </div>

            <div
                v-if="data.payment_details && data.payment_details.will_amount"
                class="mr-3 mt-3"
            >
                <vx-tooltip
                    :text="`Paid £${
                        data.payment_details.will_amount / 100
                    } for Will`"
                    position="bottom"
                >
                    <feather-icon icon="DollarSignIcon"></feather-icon>
                </vx-tooltip>
            </div>
            <div
                v-if="
                    data.payment_details && data.payment_details.printing_amount
                "
                class="mr-3 mt-3"
            >
                <vx-tooltip position="bottom" text="Paid for Printing">
                    <feather-icon icon="PrinterIcon"></feather-icon>
                </vx-tooltip>
            </div>
            <div
                v-if="
                    data.subscription_details &&
                    data.subscription_details.active
                "
                class="mr-3 mt-3"
            >
                <vx-tooltip position="bottom" text="Active Subscription">
                    <feather-icon icon="AwardIcon"></feather-icon>
                </vx-tooltip>
            </div>

            <div v-if="data.update" class="mr-3 mt-3">
                <vx-tooltip position="bottom" text="Update Will">
                    <feather-icon icon="AlertCircleIcon"></feather-icon>
                </vx-tooltip>
            </div>

            <div v-if="details.discretionary_trust" class="mr-3 mt-3">
                <vx-tooltip
                    position="bottom"
                    text="Discretionary Trust Enabled"
                >
                    <feather-icon icon="ShieldIcon"></feather-icon>
                </vx-tooltip>
            </div>

            <div v-if="details.home_protection" class="mr-3 mt-3">
                <vx-tooltip
                    position="bottom"
                    text="Home Protection Trust Enabled"
                >
                    <feather-icon icon="ShieldIcon"></feather-icon>
                </vx-tooltip>
            </div>

            <vs-dropdown
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
                vs-trigger-click
            >
                <div
                    :class="{
                        'border-secondary': saveChanges,
                        'border-grey': !saveChanges,
                    }"
                    class="p-4 border border-solid rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> Action </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu
                    class="w-full sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6"
                >
                    <vs-dropdown-item v-on:click="$emit('save')">
                        <span
                            :class="{
                                'text-secondary': saveChanges,
                                'text-primaru': !saveChanges,
                            }"
                            >Save</span
                        >
                    </vs-dropdown-item>
                    <vs-dropdown-item v-on:click="$emit('saveBack')">
                        <span
                            :class="{
                                'text-secondary': saveChanges,
                                'text-primary': !saveChanges,
                            }"
                            >Save & Back</span
                        >
                    </vs-dropdown-item>

                    <vs-dropdown-item
                        :disabled="isLocked"
                        divider
                        v-on:click="$emit('reRender')"
                    >
                        Re-draft
                    </vs-dropdown-item>

                    <vs-dropdown-item divider v-on:click="$emit('addPrinting')">
                        {{
                            data.printing_required
                                ? 'Remove Printing'
                                : 'Add Printing'
                        }}
                    </vs-dropdown-item>

                    <vs-dropdown-item
                        divider
                        v-on:click="$emit('composeEmail')"
                    >
                        Compose Email
                    </vs-dropdown-item>
                    <vs-dropdown-item v-on:click="$emit('composeSms')">
                        Compose Sms
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        :disabled="!data.checked && saveChanges"
                        v-on:click="$emit('reSendWillEmail')"
                    >
                        Send Will Approved Email
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        :disabled="!data.checked && saveChanges"
                        v-on:click="$emit('reSendWillSms')"
                    >
                        Send Will Approved Sms
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        :disabled="!data.checked && saveChanges"
                        v-on:click="$emit('reSendShare')"
                    >
                        Send Share
                    </vs-dropdown-item>

                    <vs-dropdown-item divider v-on:click="isLocked = !isLocked">
                        <span v-if="isLocked" class="text-secondary"
                            >Un-lock</span
                        >
                        <span v-else>Lock</span>
                    </vs-dropdown-item>

                    <vs-dropdown-item
                        :disabled="isLocked"
                        divider
                        v-on:click="show.deleteModal = true"
                    >
                        Delete Will
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>

            <vs-dropdown
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
                vs-trigger-click
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2">{{ statusLabel }} </span>
                    <feather-icon icon="CheckIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu
                    class="w-full sm:w-full md:w-3/4 lg:w-2/3 xl:w-1/4"
                >
                    <vs-dropdown-item v-on:click="hasPaid = false">
                        <div class="flex">
                            <span class="mr-auto">Created</span>
                            <span
                                v-if="data.uploaded_at"
                                class="text-xs text-success"
                                >{{ formatDateAndTime(data.uploaded_at) }}</span
                            >
                        </div>
                    </vs-dropdown-item>

                    <vs-dropdown-item v-on:click="hasPaid = true">
                        <div class="flex">
                            <span class="mr-auto">Paid - To Check</span>
                            <span
                                v-if="data.paid_date && data.paid"
                                class="text-xs text-success"
                                >{{ formatDateAndTime(data.paid_date) }}</span
                            >
                        </div>
                        <span
                            v-if="coupon"
                            class="text-xs text-success flex justify-end"
                            >{{ coupon.code + ' ' }}
                            <span v-if="coupon.broker || coupon.charity">
                                {{
                                    ' ' + coupon.broker.company_name ||
                                    coupon.charity.name
                                }}
                            </span>
                        </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item divider v-on:click="onHold = true">
                        <div class="flex">
                            <span class="mr-auto">On Hold</span>
                            <span
                                v-if="data.on_hold"
                                class="text-xs text-success"
                                >{{
                                    formatDateAndTime(data.on_hold_date)
                                }}</span
                            >
                        </div>
                        <span
                            v-if="data.on_hold_by"
                            class="text-xs text-success flex justify-end"
                            >{{ data.on_hold_by.email.split('@')[0] }}</span
                        >
                    </vs-dropdown-item>

                    <vs-dropdown-item divider v-on:click="contactIssue = true">
                        <div class="flex">
                            <span class="mr-auto">Contact - Admin</span>
                            <span
                                v-if="data.checked_contact_client_issue_date"
                                class="text-xs text-success"
                                >{{
                                    formatDateAndTime(
                                        data.checked_contact_client_issue_date
                                    )
                                }}</span
                            >
                        </div>
                        <span
                            v-if="data.checked_contact_client_issue_by"
                            class="text-xs text-success flex justify-end"
                            >{{
                                data.checked_contact_client_issue_by.email.split(
                                    '@'
                                )[0]
                            }}</span
                        >
                    </vs-dropdown-item>

                    <vs-dropdown-item v-on:click="contactAdvice = true">
                        <div class="flex">
                            <span class="mr-auto"
                                >Contact - Estate Planner</span
                            >
                            <span
                                v-if="data.checked_contact_client_advice_date"
                                class="text-xs text-success"
                                >{{
                                    formatDateAndTime(
                                        data.checked_contact_client_advice_date
                                    )
                                }}</span
                            >
                        </div>
                        <span
                            v-if="data.checked_contact_client_advice_by"
                            class="text-xs text-success flex justify-end"
                            >{{
                                data.checked_contact_client_advice_by.email.split(
                                    '@'
                                )[0]
                            }}</span
                        >
                    </vs-dropdown-item>

                    <vs-dropdown-item divider v-on:click="checked = true">
                        <div class="flex">
                            <span class="mr-auto">Checked - To Approve</span>
                            <span
                                v-if="data.checked_pre_final_date"
                                class="text-xs text-success"
                                >{{
                                    formatDateAndTime(
                                        data.checked_pre_final_date
                                    )
                                }}</span
                            >
                        </div>
                        <span
                            v-if="data.checked_pre_final_by"
                            class="text-xs text-success flex justify-end"
                            >{{
                                data.checked_pre_final_by.email.split('@')[0]
                            }}</span
                        >
                    </vs-dropdown-item>

                    <vs-dropdown-item
                        v-if="printingRequired"
                        divider
                        v-on:click="approved = true"
                    >
                        <div class="flex">
                            <span class="mr-auto">Approved - To Print</span>
                            <span
                                v-if="data.printed_date"
                                class="text-xs text-success"
                                >{{
                                    formatDateAndTime(data.printed_date)
                                }}</span
                            >
                        </div>
                        <span
                            v-if="data.printed_by"
                            class="text-xs text-success flex justify-end"
                            >{{ data.printed_by.email.split('@')[0] }}</span
                        >
                    </vs-dropdown-item>

                    <vs-dropdown-item
                        v-if="printingRequired"
                        divider
                        v-on:click="printed = true"
                    >
                        <div class="flex">
                            <span class="mr-auto">End - Complete</span>
                            <span
                                v-if="data.checked_date"
                                class="text-xs text-success"
                                >{{
                                    formatDateAndTime(data.checked_date)
                                }}</span
                            >
                        </div>
                        <span
                            v-if="data.checked_by"
                            class="text-xs text-success flex justify-end"
                            >{{ data.checked_by.email.split('@')[0] }}</span
                        >
                    </vs-dropdown-item>

                    <vs-dropdown-item
                        v-else
                        divider
                        v-on:click="approved = true"
                    >
                        <div class="flex">
                            <span class="mr-auto">End - Complete</span>
                            <span
                                v-if="data.checked_date"
                                class="text-xs text-success"
                                >{{
                                    formatDateAndTime(data.checked_date)
                                }}</span
                            >
                        </div>
                        <span
                            v-if="data.checked_by"
                            class="text-xs text-success flex justify-end"
                            >{{ data.checked_by.email.split('@')[0] }}</span
                        >
                    </vs-dropdown-item>

                    <vs-dropdown-item v-on:click="endStorage = true">
                        <div class="flex">
                            <span class="mr-auto">End - In Storage</span>
                            <span
                                v-if="data.in_storage_date"
                                class="text-xs text-success"
                                >{{
                                    formatDateAndTime(data.in_storage_date)
                                }}</span
                            >
                        </div>
                        <span
                            v-if="data.in_storage_by"
                            class="text-xs text-success flex justify-end"
                            >{{ data.in_storage_by.email.split('@')[0] }}</span
                        >
                    </vs-dropdown-item>

                    <vs-dropdown-item v-on:click="endHandover = true">
                        End - Handover to DM
                    </vs-dropdown-item>

                    <vs-dropdown-item v-on:click="endNoResponse = true">
                        End - No Response
                    </vs-dropdown-item>

                    <vs-dropdown-item v-on:click="endLost = true">
                        End - Lost
                    </vs-dropdown-item>

                    <vs-dropdown-item v-on:click="endDuplicate = true">
                        End - Duplicate Submission
                    </vs-dropdown-item>
                    <vs-dropdown-item v-on:click="endDeceased = true">
                        End - Deceased
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>

            <vs-dropdown
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
                vs-trigger-click
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2">Documents</span>
                    <feather-icon icon="FileIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu
                    class="w-full sm:w-full md:w-1/4 lg:w-1/3 xl:w-1/6"
                >
                    <vs-dropdown-item
                        v-if="data.combined_pdf"
                        @click="openWindow(data.combined_pdf)"
                    >
                        Combined
                    </vs-dropdown-item>

                    <vs-dropdown-item
                        v-if="data.printing_letter_pdf"
                        @click="openWindow(data.printing_letter_pdf)"
                    >
                        Printing Letter
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        v-if="data.cover_sheet_pdf"
                        @click="openWindow(data.cover_sheet_pdf)"
                    >
                        Signing Instructions
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        v-if="data.pdf"
                        @click="openWindow(data.pdf)"
                    >
                        Will
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        v-if="data.commentary_pdf"
                        @click="openWindow(data.commentary_pdf)"
                    >
                        Commentary
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        v-if="data.storage_certificate_pdf"
                        @click="openWindow(data.storage_certificate_pdf)"
                    >
                        Storage Certificate
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>
        <!-- Delete Will Modal -->
        <vs-popup :active.sync="show.deleteModal" title="Delete Will?">
            <vs-button class="mr-3" color="danger" v-on:click="deleteWill"
                >Confirm
            </vs-button>
            <vs-button type="flat" v-on:click="show.deleteModal = false"
                >Cancel
            </vs-button>
        </vs-popup>
    </div>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import Datepicker from 'vuejs-datepicker'

export default {
    name: 'ActionCard',
    mixins: [textFormat],
    props: [
        'saveChanges',
        'renderChanges',
        'dateDefer',
        'coupon',
        'username',
        'backPath',
        'approve',
        'print',
        'printingRequired',
        'inStorage',
        'check',
        'advice',
        'issue',
        'paid',
        'locked',
        'data',
        'isEndLost',
        'isOnHold',
        'isEndNoResponse',
        'isEndHandover',
        'isEndDuplicate',
        'details',
        'isEndDeceased',
    ],
    components: {
        Datepicker,
    },
    computed: {
        deferDate: {
            set(value) {
                this.$emit('deferDate', value.toISOString().slice(0, 10))
            },
            get() {
                return this.dateDefer
            },
        },
        hasPaid: {
            get: function () {
                return this.paid
            },
            set: function (value) {
                this.$emit('hasPaid', value)
                this.$emit('approved', false)
                this.$emit('checked', false)
                this.$emit('contactIssue', false)
                this.$emit('contactAdvice', false)
                this.$emit('printed', false)
                this.$emit('changed')
                this.$emit('endLost', false)
                this.$emit('endNoResponse', false)
                this.$emit('endStorage', false)
                this.$emit('endHandover', false)
                this.$emit('endDuplicate', false)
                this.$emit('endDeceased', false)
                this.$emit('onHold', false)
            },
        },
        onHold: {
            get: function () {
                return this.isOnHold
            },
            set: function (value) {
                this.$emit('approved', false)
                this.$emit('checked', false)
                this.$emit('contactIssue', false)
                this.$emit('contactAdvice', false)
                this.$emit('printed', false)
                this.$emit('changed')
                this.$emit('endLost', false)
                this.$emit('endNoResponse', false)
                this.$emit('endStorage', false)
                this.$emit('endHandover', false)
                this.$emit('endDuplicate', false)
                this.$emit('endDeceased', false)
                this.$emit('onHold', value)
            },
        },
        approved: {
            get: function () {
                return this.approve
            },
            set: function (value) {
                this.$emit('approved', value)
                this.$emit('checked', false)
                this.$emit('contactIssue', false)
                this.$emit('contactAdvice', false)
                this.$emit('printed', false)
                this.$emit('changed')
                this.$emit('endLost', false)
                this.$emit('endNoResponse', false)
                this.$emit('endStorage', false)
                this.$emit('endHandover', false)
                this.$emit('endDuplicate', false)
                this.$emit('endDeceased', false)
                this.$emit('onHold', false)
            }
        },
        printed: {
            get: function () {
                return this.print
            },
            set: function (value) {
                this.$emit('printed', value)
                this.$emit('approved', true)
                this.$emit('checked', false)
                this.$emit('contactIssue', false)
                this.$emit('contactAdvice', false)
                this.$emit('changed')
                this.$emit('endLost', false)
                this.$emit('endNoResponse', false)
                this.$emit('endStorage', false)
                this.$emit('endHandover', false)
                this.$emit('endDuplicate', false)
                this.$emit('endDeceased', false)
                this.$emit('onHold', false)
            },
        },
        checked: {
            get: function () {
                return this.check
            },
            set: function (value) {
                this.$emit('checked', value)
                this.$emit('contactIssue', false)
                this.$emit('contactAdvice', false)
                this.$emit('approved', false)
                this.$emit('printed', false)
                this.$emit('changed')
                this.$emit('endLost', false)
                this.$emit('endNoResponse', false)
                this.$emit('endStorage', false)
                this.$emit('endHandover', false)
                this.$emit('endDuplicate', false)
                this.$emit('endDeceased', false)
                this.$emit('onHold', false)
            },
        },
        contactAdvice: {
            get: function () {
                return this.advice
            },
            set: function (value) {
                this.$emit('contactAdvice', value)
                this.$emit('contactIssue', false)
                this.$emit('endStorage', false)
                this.$emit('checked', false)
                this.$emit('approved', false)
                this.$emit('printed', false)
                this.$emit('changed')
                this.$emit('endLost', false)
                this.$emit('endNoResponse', false)
                this.$emit('endHandover', false)
                this.$emit('endDuplicate', false)
                this.$emit('endDeceased', false)
                this.$emit('onHold', false)
            },
        },
        contactIssue: {
            get: function () {
                return this.issue
            },
            set: function (value) {
                this.$emit('contactIssue', value)
                this.$emit('contactAdvice', false)
                this.$emit('endStorage', false)
                this.$emit('checked', false)
                this.$emit('approved', false)
                this.$emit('printed', false)
                this.$emit('changed')
                this.$emit('endLost', false)
                this.$emit('endNoResponse', false)
                this.$emit('endHandover', false)
                this.$emit('endDuplicate', false)
                this.$emit('endDeceased', false)
                this.$emit('onHold', false)
            },
        },
        endLost: {
            get: function () {
                return this.isEndLost
            },
            set: function (value) {
                this.$emit('endLost', value)
                this.$emit('endNoResponse', false)
                this.$emit('endStorage', false)
                this.$emit('contactIssue', false)
                this.$emit('contactAdvice', false)
                this.$emit('checked', false)
                this.$emit('approved', false)
                this.$emit('printed', false)
                this.$emit('changed')
                this.$emit('endHandover', false)
                this.$emit('endDuplicate', false)
                this.$emit('endDeceased', false)
                this.$emit('onHold', false)
            },
        },
        endNoResponse: {
            get: function () {
                return this.isEndNoResponse
            },
            set: function (value) {
                this.$emit('endNoResponse', value)
                this.$emit('endLost', false)
                this.$emit('endStorage', false)
                this.$emit('contactIssue', false)
                this.$emit('contactAdvice', false)
                this.$emit('checked', false)
                this.$emit('approved', false)
                this.$emit('printed', false)
                this.$emit('endHandover', false)
                this.$emit('changed')
                this.$emit('endDuplicate', false)
                this.$emit('endDeceased', false)
                this.$emit('onHold', false)
            },
        },
        endStorage: {
            get: function () {
                return this.inStorage
            },
            set: function (value) {
                this.$emit('endStorage', value)
                this.$emit('endNoResponse', false)
                this.$emit('endLost', false)
                this.$emit('contactIssue', false)
                this.$emit('contactAdvice', false)
                this.$emit('checked', false)
                if (this.printingRequired) this.$emit('printed', true)
                this.$emit('approved', true)
                this.$emit('endHandover', false)
                this.$emit('changed')
                this.$emit('endDuplicate', false)
                this.$emit('endDeceased', false)
                this.$emit('onHold', false)
            },
        },
        endHandover: {
            get: function () {
                return this.isEndHandover
            },
            set: function (value) {
                this.$emit('endStorage', false)
                this.$emit('endNoResponse', false)
                this.$emit('endLost', false)
                this.$emit('contactIssue', false)
                this.$emit('contactAdvice', false)
                this.$emit('checked', false)
                this.$emit('printed', false)
                this.$emit('approved', false)
                this.$emit('endHandover', value)
                this.$emit('changed')
                this.$emit('endDuplicate', false)
                this.$emit('endDeceased', false)
                this.$emit('onHold', false)
            },
        },
        endDuplicate: {
            get: function () {
                return this.isEndDuplicate
            },
            set: function (value) {
                this.$emit('endStorage', false)
                this.$emit('endNoResponse', false)
                this.$emit('endLost', false)
                this.$emit('contactIssue', false)
                this.$emit('contactAdvice', false)
                this.$emit('checked', false)
                this.$emit('printed', false)
                this.$emit('approved', false)
                this.$emit('endHandover', false)
                this.$emit('changed')
                this.$emit('endDuplicate', value)
                this.$emit('endDeceased', false)
                this.$emit('onHold', false)
            },
        },
        endDeceased: {
            get: function () {
                return this.isEndDeceased
            },
            set: function (value) {
                this.$emit('endStorage', false)
                this.$emit('endNoResponse', false)
                this.$emit('endLost', false)
                this.$emit('contactIssue', false)
                this.$emit('contactAdvice', false)
                this.$emit('checked', false)
                this.$emit('printed', false)
                this.$emit('approved', false)
                this.$emit('endHandover', false)
                this.$emit('changed')
                this.$emit('endDuplicate', false)
                this.$emit('endDeceased', value)
                this.$emit('onHold', false)
            },
        },
        statusLabel() {
            if (this.isEndDeceased) return 'End - Deceased'
            if (this.inStorage) return 'End - In Storage'
            if (this.isEndLost) return 'End - Lost'
            if (this.isEndNoResponse) return 'End - No Response'
            if (this.isEndHandover) return 'End - Handover to DM'
            if (this.isEndDuplicate) return 'End - Duplicate Submission'
            if (
                (this.printingRequired && this.printed && this.approved) ||
                (!this.printingRequired && this.approved)
            )
                return 'End Complete'
            if (this.approved && this.printingRequired)
                return 'Approved - To Print'
            if (this.checked) return 'Checked - To Approve'
            if (this.contactAdvice) return 'Contact - Estate Planner'
            if (this.contactIssue) return 'Contact - Admin'
            if (this.isOnHold) return 'On Hold'
            if (this.hasPaid) return 'Paid - To Check'

            return 'Created - To Pay'
        },
        isLocked: {
            get: function () {
                return this.locked
            },
            set: function (value) {
                this.$emit('locked', value)
            },
        },
    },
    data() {
        return {
            show: {
                deleteModal: false,
            },
            date: null,
        }
    },
    methods: {
        deleteWill() {
            this.$emit('delete')
            this.show.deleteModal = false
        },
        openWindow(url) {
            window.open(url, '_blank')
        },
    },
}
</script>

<style lang="scss">
.dropdown-button-container {
    display: flex;
    align-items: center;

    .btnx {
        border-radius: 5px 0px 0px 5px;
    }

    .btn-drop {
        border-radius: 0px 5px 5px 0px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
}
</style>
