<template>
    <div :class="{ flex: !horizontal }" class="mb-3">
        <label for class="mr-auto vs-input--label">
            <span v-if="selected">{{ label || 'Person' }}</span>
        </label>
        <v-select
            :class="horizontal ? 'w-full' : 'w-1/2'"
            v-model="selected"
            :options="people"
            placeholder="Select Person"
        ></v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { http } from '@/services'

export default {
    name: 'PersonSelect',
    components: {
        vSelect,
    },
    props: [
        'personData',
        'peopleData',
        'label',
        'horizontal',
        'partnerDefault',
    ],
    watch: {
        selected(value) {
            this.$emit('selectedPerson', value)
        },
        peopleData: {
            immediate: true,
            handler(data) {
                this.people = data.map((person) => ({
                    id: person.id,
                    label: `${person.full_name} (${person.relationship})`,
                }))

                if (!this.personData && this.partnerDefault) {
                    var partnerId = data.find((person) => person.partner)
                    if (partnerId) {
                        var partner = this.people.find(
                            (person) => person.id === partnerId.id
                        )
                        this.$emit('selectedPerson', partner)
                    }
                }
            },
        },
        personData: {
            immediate: true,
            handler(value) {
                if (value)
                    this.selected = this.people.find(
                        (person) => person.id === value
                    )
                else this.selected = null
            },
        },
    },
    data() {
        return {
            people: [],
            selected: '',
        }
    },
}
</script>

<style></style>
