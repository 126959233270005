<template>
    <div class="flex mb-3">
        <label for="" class="mr-auto">Duration</label>
        <v-select
            class="w-1/2"
            v-model="selected"
            :options="durations"
            placeholder="Select Duration"
        ></v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { http } from '@/services'

export default {
    name: 'HomeProtectionDurationSelect',
    components: {
        vSelect,
    },
    props: ['durationData'],
    watch: {
        selected(value) {
            this.$emit('selectedDuration', value)
        },
        durationData: {
            immediate: true,
            handler(value) {
                if (value)
                    this.selected = this.durations.find(
                        (d) => d.value === value
                    )
                else this.selected = this.durations.find((d) => d.value === '5')
            },
        },
    },
    data() {
        return {
            durations: [
                { value: '1', label: '3 Years' },
                { value: '2', label: '5 Years' },
                { value: '3', label: '10 Years' },
                { value: '4', label: 'Until re-marries' },
                { value: '5', label: 'Until dies' },
            ],
            selected: '',
        }
    },
}
</script>

<style></style>
