<template>
    <vs-prompt
        class="email-compose"
        title="Disability Trust"
        accept-text="Save"
        @cancel="close"
        @accept="save"
        @close="close"
        :is-valid="true"
        :active.sync="showModal"
    >
        <VuePerfectScrollbar
            class="scroll-area p-4"
            :settings="settings"
            :key="$vs.rtl"
        >
            <div class="vx-row">
                <div
                    class="vx-col w-full sm:w-full md:w-full lg:w-full xl:w-full"
                >
                    <div class="flex mb-6">
                        <label for="" class="mr-auto">Enabled</label>
                        <vs-switch
                            v-model="details.disability_trust"
                            color="success"
                            vs-icon="done"
                        >
                        </vs-switch>
                    </div>
                </div>
            </div>

            <div v-show="details.disability_trust" class="vx-row">
                <div class="vx-col w-full mb-3">
                    <PersonSelect
                        :personData="details.disability_trust_disabled_person"
                        :peopleData="peopleData"
                        label="Disabled Person"
                        @selectedPerson="setPerson"
                    />
                </div>
                <div v-if="disabledPerson" class="vx-col w-full mb-3">
                    <div class="mb-3 flex">
                        <label for class="mr-auto vs-input--label">
                            <span
                                >Distribution Primary Percentage for <br />{{
                                    disabledPerson.full_name
                                }}
                            </span>
                        </label>
                        <vs-input
                            type="text"
                            :value="
                                disabledPerson.distribution_primary_percentage
                            "
                            @input="setDisabledPersonPrimaryPercentage($event)"
                        ></vs-input>
                    </div>
                </div>

                <div
                    class="flex flex-wrap-reverse items-center flex-grow justify-end"
                >
                    <vs-dropdown
                        vs-trigger-click
                        class="cursor-pointer mb-4 mr-4 items-per-page-handler"
                    >
                        <div class="flex">
                            <feather-icon
                                icon="PlusIcon"
                                svgClasses="h-4 w-4 text-success"
                            />
                        </div>

                        <vs-dropdown-menu
                            class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6"
                        >
                            <vs-dropdown-item @click="show.personModal = true"
                                >New Person</vs-dropdown-item
                            >
                            <vs-dropdown-item @click="show.groupModal = true"
                                >New Group</vs-dropdown-item
                            >
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>

                <div class="vx-col w-full mb-base">
                    <vs-table :data="people">
                        <template slot="thead">
                            <vs-th>Person</vs-th>
                            <vs-th>Trustee</vs-th>
                            <vs-th>Beneficiary</vs-th>
                            <vs-th>Default Beneficiary</vs-th>
                            <vs-th>Default Beneficiary Percentage</vs-th>
                        </template>

                        <template>
                            <vs-tr
                                :key="index"
                                v-for="(person, index) in people"
                            >
                                <vs-td>
                                    {{ person.full_name }}<br />
                                    {{ person.relationship }}
                                </vs-td>

                                <vs-td>
                                    <vs-checkbox
                                        v-model="
                                            person.disability_trust_trustee
                                        "
                                    ></vs-checkbox>
                                </vs-td>

                                <vs-td>
                                    <vs-checkbox
                                        v-model="
                                            person.disability_trust_beneficiary
                                        "
                                    ></vs-checkbox>
                                </vs-td>
                                <vs-td>
                                    <vs-checkbox
                                        v-model="
                                            person.disability_trust_default_beneficiary
                                        "
                                    ></vs-checkbox>
                                </vs-td>
                                <vs-td>
                                    <vs-input
                                        class="w-2/3"
                                        type="text"
                                        v-model="
                                            person.disability_trust_default_beneficiary_percentage
                                        "
                                    />
                                </vs-td>
                            </vs-tr>
                            <vs-tr
                                :key="index + 'group'"
                                v-for="(group, index) in groups"
                            >
                                <vs-td>
                                    {{ capitalise(group.group_name) }}<br />
                                </vs-td>

                                <vs-td> </vs-td>

                                <vs-td>
                                    <vs-checkbox
                                        v-model="
                                            group.disability_trust_beneficiary
                                        "
                                    ></vs-checkbox>
                                </vs-td>
                                <vs-td>
                                    <vs-checkbox
                                        v-model="
                                            group.disability_trust_default_beneficiary
                                        "
                                    ></vs-checkbox>
                                </vs-td>
                                <vs-td>
                                    <vs-input
                                        class="w-2/3"
                                        type="text"
                                        v-model="
                                            group.disability_trust_default_beneficiary_percentage
                                        "
                                    />
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
        </VuePerfectScrollbar>
        <PersonModal
            :show="show.personModal"
            :people="peopleData"
            :userId="userId"
            @close="closePersonModal"
        ></PersonModal>
        <GroupModal
            :show="show.groupModal"
            :userId="userId"
            @close="closeGroupModal"
        ></GroupModal>
    </vs-prompt>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import PersonSelect from '@/components/common/PersonSelect'
import PersonModal from '../Modals/PersonModal'
import GroupModal from '../Modals/GroupModal'

export default {
    name: 'DisabilityTrustModal',
    props: ['showData', 'detailsData', 'peopleData', 'groupsData'],
    mixins: [textFormat],
    components: {
        VuePerfectScrollbar,
        PersonSelect,
        PersonModal,
        GroupModal,
    },
    watch: {
        detailsData: {
            immediate: true,
            handler(value) {
                this.details.id = value.id
                this.details.disability_trust = value.disability_trust
                this.details.disability_trust_disabled_person =
                    value.disability_trust_disabled_person
            },
        },
        peopleData: {
            immediate: true,
            handler(value) {
                this.people = value.map((person) => ({
                    id: person.id,
                    full_name: person.full_name,
                    relationship: person.relationship,
                    disability_trust_trustee: person.disability_trust_trustee,
                    disability_trust_beneficiary:
                        person.disability_trust_beneficiary,
                    distribution_primary_percentage:
                        person.distribution_primary_percentage,
                    disability_trust_default_beneficiary:
                        person.disability_trust_default_beneficiary,
                    disability_trust_default_beneficiary_percentage:
                        person.disability_trust_default_beneficiary_percentage,
                }))
            },
        },
        groupsData: {
            immediate: true,
            handler(value) {
                this.groups = value.map((group) => ({
                    id: group.id,
                    group_name: group.group_name,
                    disability_trust_trustee: group.disability_trust_trustee,
                    disability_trust_beneficiary:
                        group.disability_trust_beneficiary,
                    disability_trust_default_beneficiary:
                        group.disability_trust_default_beneficiary,
                    disability_trust_default_beneficiary_percentage:
                        group.disability_trust_default_beneficiary_percentage,
                }))
            },
        },
    },
    computed: {
        showModal: {
            set(value) {},
            get() {
                return this.showData
            },
        },
        userId() {
            if (this.detailsData) return this.detailsData.user
            return null
        },
        disabledPerson() {
            return this.people.find(
                (person) =>
                    person.id === this.details.disability_trust_disabled_person
            )
        },
    },
    data() {
        return {
            details: {
                id: null,
                disability_trust: null,
                disability_trust_disabled_person: null,
            },
            people: null,
            groups: null,
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            show: {
                personModal: false,
                groupModal: false,
            },
        }
    },
    methods: {
        save() {
            var data = {
                details: this.details,
                people: this.people,
                groups: this.groups,
            }
            http.patch('disability_trust', data)
                .then((response) => {
                    this.close()
                    this.$emit('refresh')
                    this.$vs.notify({
                        text: data.details.disability_trust
                            ? 'Disability Trust Added'
                            : 'Disability Trust Removed',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: error,
                        color: 'danger',
                    })
                })
        },
        cancel() {
            this.$emit('close')
        },
        close() {
            this.$emit('close')
        },
        setPerson(value) {
            if (value) this.details.disability_trust_disabled_person = value.id
        },
        closePersonModal() {
            this.$emit('peopleListFetch')
            this.show.personModal = false
        },
        closeGroupModal() {
            this.$emit('peopleListFetch')
            this.show.groupModal = false
        },
        setDisabledPersonPrimaryPercentage(value) {
            this.people.find(
                (person) =>
                    person.id === this.details.disability_trust_disabled_person
            ).distribution_primary_percentage = value
        },
    },
}
</script>
