<template>
    <div id="willOverview">
        <div class="vx-row">
            <div v-if="willData.data" class="vx-col w-full">
                <ActionCard
                    @back="back"
                    @save="saveWill"
                    @saveBack="saveWill(true)"
                    @delete="deleteWill"
                    @reRender="renderWill"
                    @reSendWillEmail="confirmWillEmailSend"
                    @reSendWillSms="confirmWillSmsSend"
                    @reSendShare="confirmShareSend"
                    @composeEmail="show.emailComposeModal = true"
                    @composeSms="show.smsComposeModal = true"
                    @approved="willData.data.checked = $event"
                    @printed="willData.data.printed = $event"
                    @checked="willData.data.checked_pre_final = $event"
                    @contactIssue="
                        willData.data.checked_contact_client_issue = $event
                    "
                    @contactAdvice="
                        willData.data.checked_contact_client_advice = $event
                    "
                    @onHold="willData.data.on_hold = $event"
                    @hasPaid="willData.data.paid = $event"
                    @locked="willData.data.locked = $event"
                    @endLost="willData.data.end_lost = $event"
                    @endNoResponse="willData.data.end_no_response = $event"
                    @changed="saveChanges = true"
                    @endStorage="willData.data.in_storage = $event"
                    @endHandover="willData.data.end_handover = $event"
                    @deferDate="willData.data.contact_deferred_until = $event"
                    @addPrinting="confirmAddPrinting"
                    @endDuplicate="willData.data.end_duplicate = $event"
                    @endDeceased="willData.data.end_deceased = $event"
                    :dateDefer="willData.data.contact_deferred_until"
                    :paid="willData.data.paid"
                    :locked="willData.data.locked"
                    :approve="willData.data.checked"
                    :print="willData.data.printed"
                    :check="willData.data.checked_pre_final"
                    :issue="willData.data.checked_contact_client_issue"
                    :advice="willData.data.checked_contact_client_advice"
                    :saveChanges="saveChanges"
                    :renderChanges="renderChanges"
                    :backPath="backPath"
                    :printingRequired="willData.data.printing_required"
                    :data="willData.data"
                    :isOnHold="willData.data.on_hold"
                    :isEndLost="willData.data.end_lost"
                    :isEndNoResponse="willData.data.end_no_response"
                    :isEndHandover="willData.data.end_handover"
                    :isEndDuplicate="willData.data.end_duplicate"
                    :inStorage="willData.data.in_storage"
                    :isEndDeceased="willData.data.end_deceased"
                    :coupon="willData.data.coupon_used"
                    :details="willData.details"
                ></ActionCard>
            </div>
        </div>
        <div class="vx-row">
            <div
                class="vx-col w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 mb-base"
            >
                <div
                    class="vx-row mb-6"
                    v-if="
                        willData && willData.data && willData.data.user_question
                    "
                >
                    <div class="vx-col w-full">
                        <vs-alert>{{ willData.data.user_question }}</vs-alert>
                    </div>
                </div>

                <div
                    class="vx-row mb-6"
                    v-if="
                        willData &&
                        willData.details &&
                        willData.details.distribution_disabled_details
                    "
                >
                    <div class="vx-col w-full">
                        <vs-alert>{{
                            willData.details.distribution_disabled_details
                        }}</vs-alert>
                    </div>
                </div>
                <div
                    v-if="willData.data"
                    class="vx-col w-full sm:w-full md:w-full lg:w-full xl:w-full mb-base"
                >
                    <PersonalDetailsCard
                        :user="willData.user"
                        :locked="willData.data.locked"
                        @newData="willData.user.details = $event"
                        @changed="renderChanges = true"
                    ></PersonalDetailsCard>
                </div>

                <div
                    v-if="willData.advice"
                    class="vx-col w-full sm:w-full md:w-full lg:w-full xl:w-full mb-base"
                >
                    <WillsCard
                        :wills="willData.other_wills"
                        :partnersWill="willData.partners_will"
                        :selectedId="willData.data.id"
                        @compare="compare"
                    ></WillsCard>
                </div>
                <div
                    v-if="willData.user && willData.data"
                    class="vx-col w-full sm:w-full md:w-full lg:w-full xl:w-full mb-base"
                >
                    <AdviceCard
                        :advice="willData.advice"
                        @newData="adviceFetch"
                    ></AdviceCard>
                </div>
                <div v-if="willData.data" class="vx-col w-full mb-base">
                    <NotesCard
                        type="will"
                        :notes="willData.notes"
                        apiUrl="will_note"
                        :pk="willId"
                        @notesFetch="notesFetch"
                        title="Will Notes"
                    ></NotesCard>
                </div>

                <div v-if="willData.data" class="vx-col w-full mb-base">
                    <NotesCard
                        type="will"
                        :notes="willData.changes"
                        apiUrl="will_manual_change"
                        :pk="willId"
                        @notesFetch="changesFetch"
                        title="Manual Changes Notes"
                    ></NotesCard>
                </div>

                <div
                    v-if="willData.data"
                    class="vx-col w-full sm:w-full md:w-full lg:w-full xl:w-full mb-base"
                >
                    <PeopleCard
                        :people="willData.people"
                        :groups="willData.groups"
                        :charities="willData.charities"
                        :willId="willId"
                        :locked="willData.data.locked"
                        :userId="willData.data.user"
                        :assets="willData.assets"
                        :personalDetails="willData.user.details"
                        @peopleListFetch="peopleListFetch"
                        @changed="renderChanges = true"
                    ></PeopleCard>
                </div>

                <div
                    v-if="willData.details && willData.data"
                    class="vx-col w-full sm:w-full md:w-full lg:w-full xl:w-full mb-base"
                >
                    <StructureCard
                        :details="willData.details"
                        :funeral="willData.funeral"
                        :gifts="willData.gifts"
                        :locked="willData.data.locked"
                        :peopleData="willData.people"
                        :groupsData="willData.groups"
                        :trusts="willData.trusts"
                        @newData="willData.details = $event"
                        @newDataFuneral="willData.funeral = $event"
                        @newDataGifts="willData.gifts = $event"
                        @changed="renderChanges = true"
                        @refreshDetails="refreshHomeTrust"
                        @peopleListFetch="peopleListFetch"
                        @refreshTrusts="trustsFetch"
                    ></StructureCard>
                </div>

                <div
                    v-if="willData.data"
                    class="vx-col w-full sm:w-full md:w-full lg:w-full xl:w-full mb-base"
                >
                    <AssetsCard
                        :assets="willData.assets"
                        :locked="willData.data.locked"
                        @newDataAssets="willData.assets = $event"
                        @changed="renderChanges = true"
                    ></AssetsCard>
                </div>
            </div>
            <div
                v-if="willData.will_html"
                class="vx-col w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3"
            >
                <div class="vx-row">
                    <div class="vx-col w-full mb-base">
                        <vx-card
                            title="Edit"
                            :expand-action="true"
                            collapse-action
                            @expand="expandModalSwitcher"
                        >
                            <vs-tabs v-model="editTabIndex" alignment="right">
                                <vs-tab label="Will">
                                    <vue-ckeditor
                                        v-model="willData.will_html"
                                        :config="config"
                                        @focus="saveChanges = true"
                                    />
                                </vs-tab>
                                <vs-tab label="Singing Instructions">
                                    <vue-ckeditor
                                        v-model="willData.cover_sheet_html"
                                        :config="config"
                                        @focus="saveChanges = true"
                                    />
                                </vs-tab>
                                <vs-tab label="Commentary">
                                    <vue-ckeditor
                                        v-model="willData.commentary_html"
                                        :config="config"
                                        @focus="saveChanges = true"
                                    />
                                </vs-tab>
                                <vs-tab
                                    v-show="willData.printing_letter_html"
                                    label="Printing Letter"
                                >
                                    <vue-ckeditor
                                        v-model="willData.printing_letter_html"
                                        :config="config"
                                        @focus="saveChanges = true"
                                    />
                                </vs-tab>
                            </vs-tabs>
                        </vx-card>
                    </div>
                    <!--
                    <div
                        v-if="willData.printing_letter_html"
                        class="vx-col w-full mb-base"
                    >
                        <PrintingLetterCard
                            v-model="willData.printing_letter_html"
                            @changed="saveChanges = true"
                        ></PrintingLetterCard>
                    </div>
                    <div class="vx-col w-full mb-base">
                        <CoverSheetCard
                            v-model="willData.cover_sheet_html"
                            @changed="saveChanges = true"
                        ></CoverSheetCard>
                    </div>
                    <div class="vx-col w-full mb-base">
                        <CommentaryCard
                            v-model="willData.commentary_html"
                            @changed="saveChanges = true"
                        ></CommentaryCard>
                    </div>
                    -->
                </div>
            </div>
        </div>

        <!-- document expand Modals -->
        <vs-popup
            fullscreen
            title="Will"
            :active="show.expandModalWill"
            @close="show.expandModalWill = false"
            icon-pack="feather"
            icon-close="icon-minimize"
        >
            <vue-ckeditor
                v-model="willData.will_html"
                :config="config2"
                @focus="saveChanges = true"
            />
        </vs-popup>
        <vs-popup
            fullscreen
            title="Signing Instructions"
            :active="show.expandModalSigning"
            @close="show.expandModalSigning = false"
            icon-pack="feather"
            icon-close="icon-minimize"
        >
            <vue-ckeditor
                v-model="willData.cover_sheet_html"
                :config="config2"
                @focus="saveChanges = true"
            />
        </vs-popup>
        <vs-popup
            fullscreen
            title="Commentary"
            :active="show.expandModalCommentary"
            @close="show.expandModalCommentary = false"
            icon-pack="feather"
            icon-close="icon-minimize"
        >
            <vue-ckeditor
                v-model="willData.commentary_html"
                :config="config2"
                @focus="saveChanges = true"
            />
        </vs-popup>
        <vs-popup
            fullscreen
            title="Printing Instructions"
            :active="show.expandModalPrinting"
            @close="show.expandModalPrinting = false"
            icon-pack="feather"
            icon-close="icon-minimize"
        >
            <vue-ckeditor
                v-model="willData.printing_letter_html"
                :config="config2"
                @focus="saveChanges = true"
            />
        </vs-popup>

        <!-- Confirm Modal -->
        <vs-popup :title="confirm.title" :active.sync="show.confirmModal">
            <vs-button v-on:click="confirmAction" color="secondary" class="mr-3"
                >Confirm</vs-button
            >
            <vs-button v-on:click="show.confirmModal = false" type="flat"
                >Cancel</vs-button
            >
        </vs-popup>
        <EmailComposeModal
            :user="user"
            :show="show.emailComposeModal"
            @show="show.emailComposeModal = $event"
            @showDraft="handleDraftEmail"
        ></EmailComposeModal>
        <SmsComposeModal
            :user="user"
            :phone="phoneNumber"
            :show="show.smsComposeModal"
            @show="show.smsComposeModal = $event"
            @showDraft="handleDraftSms"
        ></SmsComposeModal>
        <CompareModal
            :show="show.compareModal"
            @close="show.compareModal = false"
            :thisWill="willData.will_html"
            :thisWillMeta="willData.data"
            :compareWith="compareWith"
        ></CompareModal>
        <vs-avatar
            @click="showDraftEmail"
            class="email-head"
            color="secondary"
            icon="email"
            size="70px"
            v-show="show.emailDraftButton && !show.emailComposeModal"
        />
        <vs-avatar
            @click="showDraftSms"
            class="sms-head"
            color="secondary"
            icon="chat"
            size="70px"
            v-show="show.smsDraftButton && !show.smsComposeModal"
        />
    </div>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'
import { sectionDescriptions } from '@/mixins/sectionDescriptions'
import { willStringHelpers } from '@/mixins/willStringHelpers'
import VueCkeditor from 'vue-ckeditor2'

import NotesCard from '@/components/common/Notes'
import PeopleCard from './Cards/People'
//import CoverSheetCard from './Cards/CoverSheet'
import ActionCard from './Cards/Action'
import PersonalDetailsCard from './Cards/PersonalDetails'

// import WillTab from './tabs/will'

//import CommentaryCard from './Cards/Commentary'
import StructureCard from './Cards/Structure'
import AdviceCard from './Cards/Advice'
import AssetsCard from './Cards/Assets'
import WillsCard from '../../Users/User/Cards/Wills'
import EmailComposeModal from '@/views/pages/Email/EmailComposeModal'
import SmsComposeModal from '@/views/pages/Email/SmsComposeModal'
import CompareModal from './Modals/CompareModal'
//import PrintingLetterCard from './Cards/PrintingLetter'

export default {
    name: 'WillView',
    mixins: [textFormat, sectionDescriptions, willStringHelpers],
    components: {
        //PrintingLetterCard,
        NotesCard,
        PeopleCard,
        ActionCard,
        PersonalDetailsCard,
        //CoverSheetCard,
        //CommentaryCard,
        StructureCard,
        AdviceCard,
        AssetsCard,
        WillsCard,
        EmailComposeModal,
        SmsComposeModal,
        CompareModal,
        // WillTab,
        VueCkeditor,
    },
    props: [],
    created() {
        this.willDataFetch(this.$route.params.id)
    },
    beforeRouteUpdate(to, from, next) {
        next()
        this.willDataFetch(this.$route.params.id)
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    beforeRouteLeave(to, from, next) {
        if (this.saveChanges) {
            var result = confirm('Leave without saving?')
            if (result) next()
        } else if (this.renderChanges) {
            var result2 = confirm('Leave without applying changes to the Will?')
            if (result2) next()
        } else {
            next()
        }
    },
    computed: {
        willId() {
            if (this.willData.data !== null) return this.willData.data.id
            return []
        },
        user() {
            if (this.willData.user) {
                return {
                    id: this.willData.user.details.user,
                    email: this.willData.user.email,
                }
            } else return {}
        },
        phoneNumber() {
            if (this.personalDetails && this.personalDetails.phone)
                return this.personalDetails.phone
            return null
        },
    },
    data() {
        return {
            willData: {
                data: null,
                other_wills: null,
                notes: null,
                changes: null,
                advice: null,
                people: null,
                charities: null,
                groups: null,
                details: null,
                funeral: null,
                assets: null,
                gifts: null,
                user: null,
                will_html: null,
                cover_sheet_html: null,
                commentary_html: null,
                printing_letter_html: null,
                filenames: null,
                partners_will: null,
                trusts: null,
            },
            saveChanges: false,
            renderChanges: false,
            backPath: null,
            show: {
                confirmModal: false,
                emailComposeModal: false,
                smsComposeModal: false,
                compareModal: false,
                emailDraftButton: false,
                expandModalWill: false,
                expandModalSigning: false,
                expandModalCommentary: false,
                expandModalPrinting: false,
            },
            confirm: {
                action: null,
                title: null,
            },
            compareWith: null,
            config: {
                allowedContent: true,
                fillEmptyBlocks: false,
                removeFormatAttributes: '',
                fullPage: true,
                autoParagraph: false,
                height: '1800px',
                // height: 'calc(100vh - 350px)',
                indent: false,
                breakBeforeOpen: false,
                breakAfterOpen: false,
                breakBeforeClose: false,
                breakAfterClose: false,
            },
            config2: {
                allowedContent: true,
                fillEmptyBlocks: false,
                removeFormatAttributes: '',
                fullPage: true,
                autoParagraph: false,
                height: 'calc(100vh - 175px)',
                indent: false,
                breakBeforeOpen: false,
                breakAfterOpen: false,
                breakBeforeClose: false,
                breakAfterClose: false,
            },
            editTabIndex: 0,
        }
    },
    methods: {
        confirmWillEmailSend() {
            this.show.confirmModal = true
            this.confirm.title = 'Confirm Send Email?'
            this.confirm.action = 'reSendWillEmail'
        },
        confirmWillSmsSend() {
            this.show.confirmModal = true
            this.confirm.title = 'Confirm Send Sms?'
            this.confirm.action = 'reSendWillSms'
        },
        confirmShareSend() {
            this.show.confirmModal = true
            this.confirm.title = 'Confirm Share Send?'
            this.confirm.action = 'sendShareEmails'
        },
        confirmAction() {
            this.show.confirmModal = false
            this[this.confirm.action]()
        },
        willDataFetch(id) {
            this.$vs.loading()
            this.clearWillData()
            http.get('will_detail', { params: { id: id } })
                .then((response) => {
                    this.willData.data = response.data.data
                    this.willData.other_wills = response.data.other_wills
                    this.willData.notes = response.data.notes
                    this.willData.changes = response.data.changes
                    this.willData.advice = response.data.advice
                    this.willData.people = response.data.people
                    this.willData.funeral = response.data.funeral
                    this.willData.details = response.data.details
                    this.willData.assets = response.data.assets
                    this.willData.charities = response.data.charities
                    this.willData.groups = response.data.groups
                    this.willData.gifts = response.data.gifts
                    this.willData.user = response.data.user
                    this.willData.will_html = response.data.will_html
                    this.willData.filenames = response.data.filenames
                    if (response.data.trusts)
                        this.willData.trusts = response.data.trusts
                    this.willData.cover_sheet_html =
                        response.data.cover_sheet_html
                    this.willData.commentary_html =
                        response.data.commentary_html
                    this.willData.printing_letter_html =
                        response.data.printing_letter_html
                    this.willData.partners_will = response.data.partners_will

                    this.$vs.loading.close()
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.loading.close()
                })
        },
        notesFetch(id) {
            http.get('will_note', {
                params: { will_id: this.willData.data.id },
            })
                .then((response) => {
                    this.willData.notes = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        changesFetch(id) {
            http.get('will_manual_change', {
                params: { will_id: this.willData.data.id },
            })
                .then((response) => {
                    this.willData.changes = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        adviceFetch() {
            http.get('advice_detail', {
                params: { id: this.willData.advice.id },
            })
                .then((response) => {
                    this.willData.advice = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        detailsFetch() {
            http.get('details', { params: { id: this.willData.details.id } })
                .then((response) => {
                    this.willData.details = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        peopleListFetch() {
            http.get('people_in_will_list', {
                params: { user_id: this.willData.user.details.user },
            })
                .then((response) => {
                    this.willData.people = response.data.people
                    this.willData.groups = response.data.groups
                    this.willData.charities = response.data.charities
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        renderWill() {
            this.saveSectionDescriptions().then(
                http
                    .get('will_re_render', {
                        params: {
                            user_id: this.willData.user.details.user,
                            will_id: this.willData.data.id,
                        },
                    })
                    .then((response) => {
                        this.willData.will_html = response.data.will
                        this.willData.commentary_html = response.data.commentary
                        this.willData.cover_sheet_html =
                            response.data.cover_sheet
                        this.willData.printing_letter_html =
                            response.data.printing_letter
                        if (response.data.file_name_base_cover_sheet)
                            this.willData.filenames.cover_sheet =
                                response.data.file_name_base_cover_sheet
                        if (response.data.file_name_base_commentary)
                            this.willData.filenames.commentary =
                                response.data.file_name_base_commentary
                        if (response.data.file_name_base_printing_letter)
                            this.willData.filenames.printing_letter =
                                response.data.file_name_base_printing_letter

                        this.renderChanges = false
                        this.saveChanges = true
                        this.$vs.notify({
                            title: 'Success',
                            text: 'Will Re-drafted',
                            icon: 'icon-pen-tool',
                            iconPack: 'feather',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$vs.notify({
                            text: error,
                            color: 'danger',
                        })
                    })
            )
        },
        saveWill(back = false, addPrinting = false, removePrinting = false) {
            this.$vs.loading()
            var formData = new FormData()

            formData.append('id', this.willData.data.id)
            formData.append('user', this.willData.data.user)
            formData.append('printed', this.willData.data.printed)
            formData.append('checked', this.willData.data.checked)
            formData.append('paid', this.willData.data.paid)
            formData.append('on_hold', this.willData.data.on_hold)
            formData.append('locked', this.willData.data.locked)
            if (addPrinting === true) {
                formData.append('printing_required', true)
                formData.append('add_printing', true)
            }
            if (removePrinting === true) {
                formData.append('printing_required', false)
                formData.append('remove_printing', true)
            }
            formData.append(
                'checked_contact_client_issue',
                this.willData.data.checked_contact_client_issue
            )
            formData.append(
                'checked_contact_client_advice',
                this.willData.data.checked_contact_client_advice
            )
            formData.append(
                'checked_pre_final',
                this.willData.data.checked_pre_final
            )

            formData.append(
                'will',
                new File(
                    [this.willData.will_html],
                    this.willData.filenames.will
                )
            )

            formData.append('in_storage', this.willData.data.in_storage)
            formData.append('end_lost', this.willData.data.end_lost)
            formData.append(
                'end_no_response',
                this.willData.data.end_no_response
            )
            formData.append('end_handover', this.willData.data.end_handover)
            formData.append('end_duplicate', this.willData.data.end_duplicate)
            formData.append('end_deceased', this.willData.data.end_deceased)

            if (!back) formData.append('sync', true)

            if (this.willData.data.contact_deferred_until)
                formData.append(
                    'contact_deferred_until',
                    this.willData.data.contact_deferred_until
                )
            if (this.willData.cover_sheet_html)
                formData.append(
                    'cover_sheet',
                    new File(
                        [this.willData.cover_sheet_html],
                        this.willData.filenames.cover_sheet
                    )
                )
            if (this.willData.commentary_html)
                formData.append(
                    'commentary',
                    new File(
                        [this.willData.commentary_html],
                        this.willData.filenames.commentary
                    )
                )
            if (this.willData.printing_letter_html && !removePrinting)
                formData.append(
                    'printing_letter',
                    new File(
                        [this.willData.printing_letter_html],
                        this.willData.filenames.printing_letter
                    )
                )

            http.patch('will_detail', formData)
                .then((response) => {
                    this.$vs.loading.close()
                    this.$store.dispatch('willsToApproveFetch')
                    this.$store.dispatch('willsToCheckFetch')
                    this.$store.dispatch('willsToContactFetch')
                    this.$store.dispatch('willsToPrintFetch')

                    this.saveChanges = false
                    this.$vs.notify({
                        text: 'Will saved',
                        color: 'success',
                    })
                    if (back) {
                        this.back()
                    } else {
                        this.willDataFetch(this.$route.params.id)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.loading.close()
                })
        },
        back() {
            if (this.backPath) this.$router.push(this.backPath)
            else this.$router.push('/wills/pipeline')
        },
        reSendWillEmail() {
            http.get('will_re_send_email', {
                params: { id: this.willData.data.id },
            })
                .then((response) => {
                    this.$vs.notify({
                        text: 'Will sent',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'danger',
                    })
                })
        },
        reSendWillSms() {
            http.get('will_re_send_sms', {
                params: { id: this.willData.data.id },
            })
                .then((response) => {
                    this.$vs.notify({
                        text: 'Will sent',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'secondary',
                    })
                })
        },
        sendShareEmails() {
            var postData = {
                will_id: this.willData.data.id,
                user_id: this.willData.data.user,
            }
            http.post('send_manual_share_email', postData)
                .then((response) => {
                    this.$vs.notify({
                        title: 'Share',
                        text: 'Share emails sent',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Share',
                        text: error,
                        color: 'warning',
                    })
                })
            this.show.shareModal = false
        },
        deleteWill() {
            http.delete('will_detail', {
                params: { id: this.willData.data.id },
            })
                .then((response) => {
                    this.$router.push('/wills/all')
                    this.$vs.notify({
                        text: 'Will deleted',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: error,
                        color: 'warning',
                    })
                })
        },
        oneOffPaymentFetch() {
            http.get('gocardless_payment_detail', {
                params: {
                    payment_id: this.willData.data.payment_details
                        .go_cardless_payment_id,
                },
            })
                .then((response) => {
                    this.willData.payment = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        subscriptiontFetch() {
            http.get('gocardless_subscription_detail', {
                params: {
                    subscription_id: this.willData.data.payment_details
                        .go_cardless_subscription_id,
                },
            })
                .then((response) => {
                    this.willData.subscription = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        compare(value) {
            this.show.compareModal = true
            this.compareWith = value
        },
        refreshHomeTrust() {
            this.detailsFetch()
            this.peopleListFetch()
        },
        confirmAddPrinting() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'primary',
                title: `Confirm`,
                text: this.willData.data.printing_required
                    ? 'Remove Printing from this will?'
                    : 'Add printing to this will?',
                accept: this.addPrinting,
                acceptText: this.willData.data.printing_required
                    ? 'Remove'
                    : 'Add',
            })
        },
        async addPrinting() {
            if (this.willData.data.printing_required)
                this.saveWill(false, false, true)
            else this.saveWill(false, true)
        },
        handleDraftEmail(value) {
            this.show.emailDraftButton = value
        },
        showDraftEmail() {
            this.show.emailDraftButton = false
            this.show.emailComposeModal = true
        },
        handleDraftSms(value) {
            this.show.smsDraftButton = value
        },
        showDraftSms() {
            this.show.smsDraftButton = false
            this.show.smsComposeModal = true
        },
        clearWillData() {
            this.willData.data = null
            this.willData.other_wills = null
            this.willData.notes = null
            this.willData.changes = null
            this.willData.advice = null
            this.willData.people = null
            this.willData.charities = null
            this.willData.groups = null
            this.willData.details = null
            this.willData.funeral = null
            this.willData.assets = null
            this.willData.gifts = null
            this.willData.user = null
            this.willData.will_html = null
            this.willData.cover_sheet_html = null
            this.willData.commentary_html = null
            this.willData.printing_letter_html = null
            this.willData.filenames = null
            this.willData.partners_will = null
        },
        trustsFetch() {
            http.get('trusts', { params: { id: this.willData.details.user } })
                .then((response) => {
                    this.willData.trusts = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        expandModalSwitcher() {
            if (this.editTabIndex === 0) this.show.expandModalWill = true
            else if (this.editTabIndex === 1)
                this.show.expandModalSigning = true
            else if (this.editTabIndex === 2)
                this.show.expandModalCommentary = true
            else if (this.editTabIndex === 3)
                this.show.expandModalPrinting = true
        },
    },
}
</script>
