<template>
    <SideBarModal
        title="Assets & Liabilities"
        :show="show"
        @close="$emit('close')"
    >
        <template v-slot:content>
            <vs-collapse>
                <vs-collapse-item>
                    <div slot="header">Assets</div>
                    <!-- Residential Property -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox
                                v-model="assetsData.residential_property"
                                >Residen Prop</vs-checkbox
                            >
                        </div>
                        <vs-switch
                            class="w-1/6 self-center"
                            v-model="assetsData.residential_property_joint"
                        >
                            <span slot="on">Joint</span>
                            <span slot="off">Sole</span>
                        </vs-switch>
                        <vs-input-number
                            v-model="assetsData.residential_property_amount_new"
                        />
                    </div>
                    <vs-divider></vs-divider>
                    <!-- BTL Property -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.blt_property"
                                >BTL Property</vs-checkbox
                            >
                        </div>
                        <vs-switch
                            class="w-1/6 self-center"
                            v-model="assetsData.blt_property_joint"
                        >
                            <span slot="on">Joint</span>
                            <span slot="off">Sole</span>
                        </vs-switch>
                        <vs-input-number
                            v-model="assetsData.blt_property_amount_new"
                        />
                    </div>
                    <vs-divider></vs-divider>

                    <!-- Saving Bank -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.savings_bank"
                                >Savings/Bank</vs-checkbox
                            >
                        </div>
                        <vs-switch
                            class="w-1/6 self-center"
                            v-model="assetsData.savings_bank_joint"
                        >
                            <span slot="on">Joint</span>
                            <span slot="off">Sole</span>
                        </vs-switch>
                        <vs-input-number
                            v-model="assetsData.savings_bank_amount_new"
                        />
                    </div>
                    <vs-divider></vs-divider>

                    <!-- Life Insurance -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.life_insurance"
                                >Life Insurance</vs-checkbox
                            >
                        </div>
                        <vs-switch
                            class="w-1/6 self-center"
                            v-model="assetsData.life_insurance_joint"
                        >
                            <span slot="on">Joint</span>
                            <span slot="off">Sole</span>
                        </vs-switch>
                        <vs-input-number
                            v-model="assetsData.life_insurance_amount_new"
                        />
                    </div>
                    <vs-divider></vs-divider>

                    <!-- Vehicle -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.vehicle"
                                >Vehicle</vs-checkbox
                            >
                        </div>
                        <vs-input-number
                            v-model="assetsData.vehicle_amount_new"
                        />
                    </div>
                    <vs-divider></vs-divider>

                    <!-- Pension -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.pension"
                                >Pension</vs-checkbox
                            >
                        </div>
                        <vs-input-number
                            v-model="assetsData.pension_amount_new"
                        />
                    </div>
                    <vs-divider></vs-divider>

                    <!-- Shares/Investments -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.shares_investments"
                                >Shares/Invest</vs-checkbox
                            >
                        </div>
                        <vs-switch
                            class="w-1/6 self-center"
                            v-model="assetsData.shares_investments_joint"
                        >
                            <span slot="on">Joint</span>
                            <span slot="off">Sole</span>
                        </vs-switch>
                        <vs-input-number
                            v-model="assetsData.shares_investments_amount_new"
                        />
                    </div>
                    <vs-divider></vs-divider>

                    <!-- Endowments -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.endowment"
                                >Endowment</vs-checkbox
                            >
                        </div>
                        <vs-switch
                            class="w-1/6 self-center"
                            v-model="assetsData.endowment_joint"
                        >
                            <span slot="on">Joint</span>
                            <span slot="off">Sole</span>
                        </vs-switch>
                        <vs-input-number
                            v-model="assetsData.endowment_amount_new"
                        />
                    </div>
                    <vs-divider></vs-divider>

                    <!-- Business -->
                    <div class="flex justify-between mb-3">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.business"
                                >Business</vs-checkbox
                            >
                        </div>
                        <vs-input-number
                            v-model="assetsData.business_amount_new"
                        />
                    </div>
                    <p class="text-xs">Business type</p>
                    <vs-select
                        class="w-full mb-3"
                        v-model="assetsData.business_type"
                    >
                        <vs-select-item
                            value="partnership"
                            text="Partnership"
                        />
                        <vs-select-item
                            value="sole trader"
                            text="Sole Trader"
                        />
                        <vs-select-item
                            value="limited company"
                            text="Limited Company"
                        />
                    </vs-select>
                    <p class="text-xs">Shareholders</p>
                    <vs-select
                        class="w-full mb-3"
                        v-model="assetsData.shareholders"
                    >
                        <vs-select-item value="1%-50%" text="1%-50%" />
                        <vs-select-item value="51%-99%" text="51%-99%" />
                        <vs-select-item value="100%" text="100%" />
                    </vs-select>
                    <vs-divider></vs-divider>

                    <!-- Household -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.household"
                                >Household</vs-checkbox
                            >
                        </div>
                        <vs-input-number
                            v-model="assetsData.household_amount_new"
                        />
                    </div>
                    <vs-divider></vs-divider>

                    <!-- Gold Jewellery -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.gold_jewellery"
                                >Gold/Jewellery</vs-checkbox
                            >
                        </div>
                        <vs-input-number
                            v-model="assetsData.gold_jewellery_amount_new"
                        />
                    </div>
                </vs-collapse-item>
                <vs-collapse-item>
                    <div slot="header">Liabilities</div>
                    <vs-divider></vs-divider>

                    <!-- Residential Mortgage -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox
                                v-model="assetsData.residential_mortgage"
                                >Residential Mort</vs-checkbox
                            >
                        </div>
                        <vs-switch
                            class="w-1/6 self-center"
                            v-model="assetsData.residential_mortgage_joint"
                        >
                            <span slot="on">Joint</span>
                            <span slot="off">Sole</span>
                        </vs-switch>
                        <vs-input-number
                            v-model="assetsData.residential_mortgage_amount_new"
                        />
                    </div>
                    <vs-divider></vs-divider>

                    <!-- BTL Mortgage -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.blt_mortgage"
                                >BTL Mortgage</vs-checkbox
                            >
                        </div>
                        <vs-switch
                            class="w-1/6 self-center"
                            v-model="assetsData.blt_mortgage_joint"
                        >
                            <span slot="on">Joint</span>
                            <span slot="off">Sole</span>
                        </vs-switch>
                        <vs-input-number
                            v-model="assetsData.blt_mortgage_amount_new"
                        />
                    </div>
                    <vs-divider></vs-divider>

                    <!-- Credit Cards -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.credit_cards"
                                >Credit Cards</vs-checkbox
                            >
                        </div>
                        <vs-input-number
                            v-model="assetsData.credit_cards_amount_new"
                        />
                    </div>
                    <vs-divider></vs-divider>

                    <!-- Loans -->
                    <div class="flex justify-between">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.loans"
                                >Loans</vs-checkbox
                            >
                        </div>
                        <vs-input-number
                            v-model="assetsData.loans_amount_new"
                        />
                    </div>
                </vs-collapse-item>
                <vs-collapse-item>
                    <div slot="header">Other</div>
                    <vs-divider></vs-divider>

                    <!-- Assets Outside UK -->
                    <div class="flex justify-between mb-6">
                        <div class="self-center">
                            <vs-checkbox v-model="assetsData.assets_outside_uk"
                                >Assets Outside UK</vs-checkbox
                            >
                        </div>
                    </div>
                    <vs-divider></vs-divider>

                    <!-- Know by other names -->
                    <p class="text-xs mb-2">Known by other name?</p>
                    <div class="flex">
                        <div class="w-1/3 mr-3">
                            <vs-select
                                class="w-full"
                                v-model="assetsData.known_by_other_name"
                            >
                                <vs-select-item value="yes" text="Yes" />
                                <vs-select-item value="no" text="No" />
                            </vs-select>
                        </div>
                        <div class="w-2/3">
                            <vs-input
                                class="w-full"
                                v-model="assetsData.other_name"
                            />
                        </div>
                    </div>
                </vs-collapse-item>
            </vs-collapse>
        </template>
        <template v-slot:buttons>
            <vs-button class="mr-6" @click="assetsSave">Save</vs-button>
            <vs-button type="border" color="danger" @click="$emit('close')"
                >Cancel</vs-button
            >
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'

import SideBarModal from '@/components/common/SideBarModal'

export default {
    name: 'AssetsModal',
    components: {
        SideBarModal,
    },
    props: ['assets', 'show'],
    watch: {
        assets: {
            deep: true,
            immediate: true,
            handler(data) {
                this.assetsData = { ...data }
            },
        },
    },
    data() {
        return {
            assetsData: null,
        }
    },
    computed: {},
    methods: {
        assetsSave() {
            if (this.assetsData && this.assetsData.id) {
                http.patch('assets', this.assetsData)
                    .then((response) => {
                        this.$emit('newData', response.data)
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Saved',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
    },
}
</script>
