<template>
    <SideBarModal title="Will Structure" :show="show" @close="$emit('close')">
        <template v-slot:content>
            <h5 class="mb-3">Sections</h5>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-checkbox v-model="structure.executor_professional"
                        >Professional Executors</vs-checkbox
                    >
                </div>
            </div>

            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-checkbox
                        v-model="structure.executor_professional_and_family"
                        >Professional Executors & Family</vs-checkbox
                    >
                </div>
            </div>

            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-checkbox v-model="structure.same_as_executors"
                        >Executors as trustees</vs-checkbox
                    >
                </div>
            </div>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-checkbox v-model="structure.pets_yes_no"
                        >Pets section</vs-checkbox
                    >
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="structure.amount"
                        label-placeholder="Amount left to pets"
                    />
                </div>
            </div>
            <h5 class="mb-3">Distribution</h5>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-checkbox v-model="structure.distribution_minor_18"
                        >Minors inherit at 18</vs-checkbox
                    >
                </div>
            </div>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-checkbox v-model="structure.distribution_minor_21"
                        >Minors inherit at 21</vs-checkbox
                    >
                </div>
            </div>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-checkbox v-model="structure.distribution_minor_25"
                        >Minors inherit at 25</vs-checkbox
                    >
                </div>
            </div>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-checkbox
                        v-model="structure.distribution_all_die_backup_table"
                        >Substitutional Provisions</vs-checkbox
                    >
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <vs-button class="mr-6" @click="detailsSave">Save</vs-button>
            <vs-button type="border" color="danger" @click="$emit('close')"
                >Cancel</vs-button
            >
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'

import SideBarModal from '@/components/common/SideBarModal'

export default {
    name: 'StructureModal',
    components: {
        SideBarModal,
    },
    props: ['details', 'show'],
    watch: {
        details: {
            deep: true,
            immediate: true,
            handler(data) {
                this.structure = { ...data }
            },
        },
    },
    data() {
        return {
            person: null,
            structure: null,
        }
    },
    computed: {},
    methods: {
        detailsSave() {
            if (this.details && this.details.id) {
                http.patch('details', this.structure)
                    .then((response) => {
                        this.$emit('newData', response.data)
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Saved',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
    },
}
</script>
