<template>
    <vs-prompt
        class="email-compose"
        title="Home Protection Trust"
        accept-text="Save"
        @cancel="close"
        @accept="save"
        @close="close"
        :is-valid="true"
        :active.sync="showModal"
    >
        <VuePerfectScrollbar
            class="scroll-area p-4"
            :settings="settings"
            :key="$vs.rtl"
        >
            <div class="vx-row">
                <div
                    class="vx-col w-full sm:w-full md:w-full lg:w-full xl:w-full"
                >
                    <div class="flex mb-6">
                        <label for="" class="mr-auto">Enabled</label>
                        <vs-switch
                            v-model="details.home_protection"
                            color="success"
                            vs-icon="done"
                        >
                        </vs-switch>
                    </div>
                </div>
            </div>

            <div v-show="details.home_protection" class="vx-row">
                <div class="vx-col w-full mb-3">
                    <div class="flex mb-6">
                        <label for="" class="mr-auto">Home Address</label>
                        <vs-switch
                            v-model="details.home_protection_use_home_address"
                            color="success"
                            vs-icon="done"
                        >
                        </vs-switch>
                    </div>
                    <div v-show="!details.home_protection_use_home_address">
                        <div class="flex mb-3">
                            <label for="" class="mr-auto">Address Line 1</label>
                            <vs-input
                                v-model="details.home_protection_address_1"
                                type="text"
                                class="w-1/2"
                            />
                        </div>
                        <div class="flex mb-3">
                            <label for="" class="mr-auto">Address Line 2</label>
                            <vs-input
                                v-model="details.home_protection_address_2"
                                type="text"
                                class="w-1/2"
                            />
                        </div>
                        <div class="flex mb-3">
                            <label for="" class="mr-auto">Address Line 3</label>
                            <vs-input
                                v-model="details.home_protection_address_3"
                                type="text"
                                class="w-1/2"
                            />
                        </div>
                    </div>
                    <PersonSelect
                        :personData="details.home_protection_occupant"
                        :peopleData="peopleData"
                        label="Occupant"
                        @selectedPerson="setPerson"
                        :partnerDefault="true"
                    />
                    <HomeProtectionDurationSelect
                        :durationData="details.home_protection_duration"
                        @selectedDuration="setDuration"
                    />
                </div>

                <div
                    class="flex flex-wrap-reverse items-center flex-grow justify-end"
                >
                    <vs-dropdown
                        vs-trigger-click
                        class="cursor-pointer mb-4 mr-4 items-per-page-handler"
                    >
                        <div class="flex">
                            <feather-icon
                                icon="PlusIcon"
                                svgClasses="h-4 w-4 text-success"
                            />
                        </div>

                        <vs-dropdown-menu
                            class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/6 xl:w-1/6"
                        >
                            <vs-dropdown-item @click="show.personModal = true"
                                >New Person</vs-dropdown-item
                            >
                            <!--<vs-dropdown-item @click="show.groupModal=true">New Group</vs-dropdown-item>-->
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>

                <div class="vx-col w-full mb-base">
                    <vs-table :data="people">
                        <template slot="thead">
                            <vs-th>Person</vs-th>
                            <vs-th></vs-th>
                            <vs-th>Trustee</vs-th>
                            <vs-th>Beneficiary</vs-th>
                            <vs-th>Percentage</vs-th>
                        </template>

                        <template>
                            <vs-tr
                                :key="index"
                                v-for="(person, index) in people"
                            >
                                <vs-td> {{ person.full_name }}<br /> </vs-td>
                                <vs-td>
                                    {{ person.relationship }}
                                </vs-td>

                                <vs-td>
                                    <vs-checkbox
                                        v-model="person.home_protection_trustee"
                                    ></vs-checkbox>
                                </vs-td>

                                <vs-td>
                                    <vs-checkbox
                                        v-model="
                                            person.home_protection_beneficiary
                                        "
                                    ></vs-checkbox>
                                </vs-td>

                                <vs-td>
                                    <vs-input
                                        class="w-2/3"
                                        type="text"
                                        v-model="
                                            person.home_protection_beneficiary_percentage
                                        "
                                    />
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
        </VuePerfectScrollbar>
        <PersonModal
            :show="show.personModal"
            :people="peopleData"
            :userId="userId"
            @close="closePersonModal"
        ></PersonModal>
    </vs-prompt>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import PersonSelect from '@/components/common/PersonSelect'
import HomeProtectionDurationSelect from '../Helpers/HomeProtectionDurationSelect'
import PersonModal from '../Modals/PersonModal'

export default {
    name: 'HomeProtectionModal',
    props: ['showData', 'detailsData', 'peopleData'],
    mixins: [textFormat],
    components: {
        VuePerfectScrollbar,
        PersonSelect,
        HomeProtectionDurationSelect,
        PersonModal,
    },
    watch: {
        detailsData: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.details.id = value.id
                    this.details.home_protection = value.home_protection
                    this.details.home_protection_duration =
                        value.home_protection_duration
                    this.details.home_protection_occupant =
                        value.home_protection_occupant
                    this.details.home_protection_use_home_address =
                        value.home_protection_use_home_address
                    this.details.home_protection_address_1 =
                        value.home_protection_address_1
                    this.details.home_protection_address_2 =
                        value.home_protection_address_2
                    this.details.home_protection_address_3 =
                        value.home_protection_address_3
                }
            },
        },
        peopleData: {
            immediate: true,
            handler(value) {
                this.people = value.map((person) => ({
                    id: person.id,
                    full_name: person.full_name,
                    relationship: person.relationship,
                    home_protection_trustee: person.home_protection_trustee,
                    home_protection_beneficiary:
                        person.home_protection_beneficiary,
                    home_protection_beneficiary_percentage:
                        person.home_protection_beneficiary_percentage,
                }))
            },
        },
    },
    computed: {
        showModal: {
            set(value) {},
            get() {
                return this.showData
            },
        },
        userId() {
            if (this.detailsData) return this.detailsData.user
            return null
        },
    },
    data() {
        return {
            details: {
                id: null,
                home_protection: null,
                home_protection_duration: null,
                home_protection_occupant: null,
                home_protection_use_home_address: null,
                home_protection_address_1: null,
                home_protection_address_2: null,
                home_protection_address_3: null,
            },
            people: null,
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            show: {
                personModal: false,
                groupModal: false,
            },
        }
    },
    methods: {
        save() {
            var data = {
                details: this.details,
                people: this.people,
            }
            http.patch('home_protection', data)
                .then((response) => {
                    this.close()
                    this.$emit('refresh')
                    this.$vs.notify({
                        text: data.details.home_protection
                            ? 'Home Protection Added'
                            : 'Home Protection Removed',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: error,
                        color: 'danger',
                    })
                })
        },
        cancel() {
            this.$emit('close')
        },
        close() {
            this.$emit('close')
        },
        setPerson(value) {
            if (value) this.details.home_protection_occupant = value.id
        },
        setDuration(value) {
            this.details.home_protection_duration = value.value
        },
        closePersonModal() {
            this.$emit('peopleListFetch')
            this.show.personModal = false
        },
        closeGroupModal() {
            this.$emit('peopleListFetch')
            this.show.groupModal = false
        },
    },
}
</script>
